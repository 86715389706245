import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import { 
  Monitor, 
  Code, 
  Search, 
  Gauge, 
  ShoppingBag, 
  Palette,
  Zap,
  ArrowRight,
  Globe,
  Laptop,
  Smartphone,
  Shield
} from 'lucide-react';

function WebDesign() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  // Add Schema.org data
  useEffect(() => {
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Web Tasarım Hizmeti",
      "description": "Modern teknolojiler ve yaratıcı tasarımlarla markanızı dijital dünyada en iyi şekilde temsil ediyoruz.",
      "provider": {
        "@type": "Organization",
        "name": "AjansPixel",
        "image": "https://ajanspixel.com/logo.png",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Küçükbakkalköy Mah. Kocasinan Cad. Selvili Sok, Canan Business Center No:4 K:10 D:68",
          "addressLocality": "Ataşehir",
          "addressRegion": "İstanbul",
          "postalCode": "34755",
          "addressCountry": "TR"
        },
        "telephone": "+905375169044",
        "email": "info@ajanspixel.com"
      },
      "areaServed": {
        "@type": "Country",
        "name": "Türkiye"
      },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Web Tasarım Hizmetleri",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Responsive Tasarım",
              "description": "Tüm cihazlarda mükemmel görünen ve sorunsuz çalışan web siteleri"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "SEO Uyumlu Kodlama",
              "description": "Arama motorlarında üst sıralarda yer almanız için optimize edilmiş kodlama"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Modern UI/UX",
              "description": "Kullanıcı odaklı, modern ve estetik arayüz tasarımları"
            }
          }
        ]
      }
    };

    // Add schema data to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schemaData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const features = [
    {
      icon: Monitor,
      title: "Responsive Tasarım",
      desc: "Tüm cihazlarda (masaüstü, tablet, mobil) mükemmel görünen ve sorunsuz çalışan web siteleri tasarlıyoruz.",
      gradient: "from-[#E3F2FD] to-[#BBDEFB]"
    },
    {
      icon: Search,
      title: "SEO Uyumlu Kodlama",
      desc: "Arama motorlarında üst sıralarda yer almanız için optimize edilmiş, temiz ve semantik kodlama yapıyoruz.",
      gradient: "from-[#FFF3E0] to-[#FFE0B2]"
    },
    {
      icon: Gauge,
      title: "Hızlı Yükleme Süreleri",
      desc: "Performans optimizasyonları ile hızlı açılan, kullanıcı deneyimini önemseyen web siteleri geliştiriyoruz.",
      gradient: "from-[#E8F5E9] to-[#C8E6C9]"
    },
    {
      icon: Palette,
      title: "Modern UI/UX",
      desc: "Kullanıcı odaklı, modern ve estetik arayüz tasarımları ile markanızı en iyi şekilde yansıtıyoruz.",
      gradient: "from-[#F3E5F5] to-[#E1BEE7]"
    },
    {
      icon: Globe,
      title: "WordPress Entegrasyonu",
      desc: "Kolay yönetilebilir WordPress altyapısı ile içeriklerinizi zahmetsizce güncelleyebilirsiniz.",
      gradient: "from-[#E0F7FA] to-[#B2EBF2]"
    },
    {
      icon: ShoppingBag,
      title: "E-ticaret Çözümleri",
      desc: "WooCommerce ve özel e-ticaret sistemleri ile online satış kanallarınızı güçlendiriyoruz.",
      gradient: "from-[#FBE9E7] to-[#FFCCBC]"
    },
    {
      icon: Shield,
      title: "Güvenlik Önlemleri",
      desc: "SSL sertifikası ve güvenlik duvarı ile sitenizi siber tehditlere karşı koruyoruz.",
      gradient: "from-[#ECEFF1] to-[#CFD8DC]"
    },
    {
      icon: Smartphone,
      title: "PWA Desteği",
      desc: "Progressive Web App teknolojisi ile web sitenizi mobil uygulama gibi kullanılabilir hale getiriyoruz.",
      gradient: "from-[#E8EAF6] to-[#C5CAE9]"
    },
    {
      icon: Laptop,
      title: "Bakım & Destek",
      desc: "7/24 teknik destek ve düzenli bakım hizmetleri ile sitenizin sorunsuz çalışmasını sağlıyoruz.",
      gradient: "from-[#FCE4EC] to-[#F8BBD0]"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-30" />
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-20" />
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-10" />
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">50+</span>
                    <span className="text-gray-600 font-medium">Web Projesi</span>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                Modern
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">UI/UX Tasarım</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">Çözümleri</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                Modern teknolojiler ve yaratıcı tasarımlarla markanızı dijital dünyada en iyi şekilde temsil ediyoruz.
              </p>
              
              <div className="mt-12 flex items-center gap-6">
                <button 
                  onClick={() => setIsContactFormOpen(true)}
                  className="px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 inline-flex items-center gap-2"
                >
                  Hemen Başlayın
                  <Zap className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <div className="relative">
                <div className="absolute inset-0 bg-[#FF6B00] opacity-10 rounded-[40px]"></div>
                <img 
                  src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=800&fit=crop" 
                  alt="Web Tasarım" 
                  className="relative z-10 w-full h-[600px] object-cover object-center rounded-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Özellikler ve Hizmetler
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Modern ve profesyonel web sitelerinin sahip olması gereken tüm özellikler
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="group relative bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]">
                  <div className={`w-16 h-16 mb-6 bg-gradient-to-br ${feature.gradient} rounded-2xl flex items-center justify-center`}>
                    <Icon className="h-8 w-8 text-[#2196F3]" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
                  <p className="text-gray-600">{feature.desc}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Portfolio Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Portfolyo
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Başarıyla tamamladığımız projelerden örnekler
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "E-ticaret Sitesi",
                desc: "Modern ve kullanıcı dostu bir online alışveriş deneyimi",
                image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=500&fit=crop"
              },
              {
                title: "Kurumsal Web Sitesi",
                desc: "Profesyonel ve etkileyici kurumsal kimlik",
                image: "https://images.unsplash.com/photo-1497366216548-37526070297c?w=800&h=500&fit=crop"
              },
              {
                title: "Blog Platformu",
                desc: "İçerik odaklı, hızlı ve SEO uyumlu blog sitesi",
                image: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?w=800&h=500&fit=crop"
              }
            ].map((project, index) => (
              <div key={index} className="group relative overflow-hidden rounded-2xl">
                <div className="relative h-64">
                  <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-70 transition-all duration-300"></div>
                  <img 
                    src={project.image} 
                    alt={project.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 p-6 flex flex-col justify-end">
                    <h3 className="text-xl font-bold text-white mb-2">{project.title}</h3>
                    <p className="text-gray-200">{project.desc}</p>
                    <a href="#" className="mt-4 inline-flex items-center text-[#4ADE80] font-medium">
                      Detayları İncele
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-3xl p-12 relative overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute -top-12 -left-12 w-72 h-72 bg-[#FF5733] rounded-full"></div>
              <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute -bottom-12 -right-12 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
            </div>
            
            <div className="relative text-center max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-6">Projeniz İçin Teklif Alın</h2>
              <p className="text-lg text-gray-600 mb-8">
                Modern ve etkileyici bir web sitesi için hemen iletişime geçin.
              </p>
              <button 
                onClick={() => setIsContactFormOpen(true)}
                className="inline-flex items-center px-8 py-4 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 group"
              >
                Hemen İletişime Geçin
                <Zap className="ml-2 h-5 w-5 transform group-hover:-rotate-12 transition-transform duration-300" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      {/* Quick Contact Form */}
      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </div>
  );
}

export default WebDesign;