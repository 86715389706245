import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.tsx';
import './index.css';
import PerformanceMetrics from './components/PerformanceMetrics';
import SecurityHeaders from './components/SecurityHeaders';
import AnalyticsProvider from './components/AnalyticsProvider';
import ScrollToTop from './components/ScrollToTop';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Register Service Worker
serviceWorkerRegistration.register();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <AnalyticsProvider>
        <SecurityHeaders />
        <PerformanceMetrics />
        <App />
      </AnalyticsProvider>
    </BrowserRouter>
  </StrictMode>
);