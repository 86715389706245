import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin/AdminLayout';
import CategoryForm from '../../../components/admin/blog/CategoryForm';
import { blogService } from '../../../services/blogService';
import { 
  Plus, 
  Pencil, 
  Trash2, 
  AlertCircle,
  X,
  Folder
} from 'lucide-react';
import type { BlogCategory } from '../../../types/blog';

export default function Categories() {
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<BlogCategory | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await blogService.getCategories();
      setCategories(data);
    } catch (err: any) {
      setError('Kategoriler yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      if (selectedCategory) {
        await blogService.updateCategory({ id: selectedCategory.id, ...formData });
      } else {
        await blogService.createCategory(formData);
      }
      setIsFormOpen(false);
      setSelectedCategory(null);
      fetchCategories();
    } catch (err: any) {
      setError('Kategori kaydedilirken bir hata oluştu');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Bu kategoriyi silmek istediğinize emin misiniz?')) return;

    try {
      await blogService.deleteCategory(id);
      fetchCategories();
    } catch (err: any) {
      setError('Kategori silinirken bir hata oluştu');
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Blog Kategorileri</h1>
            <p className="mt-2 text-sm text-gray-600">
              Blog kategorilerini yönetin
            </p>
          </div>
          <button
            onClick={() => {
              setSelectedCategory(null);
              setIsFormOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300"
          >
            <Plus className="h-5 w-5 mr-2" />
            Yeni Kategori
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {/* Categories List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {categories.map(category => (
              <div 
                key={category.id}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
                      <Folder className="h-5 w-5 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">{category.name}</h3>
                      <p className="text-sm text-gray-500">{category.slug}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        setSelectedCategory(category);
                        setIsFormOpen(true);
                      }}
                      className="text-gray-400 hover:text-[#4ADE80] transition-colors"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(category.id)}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                {category.description && (
                  <p className="mt-4 text-sm text-gray-600">{category.description}</p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Category Form Modal */}
      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-md w-full">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">
                  {selectedCategory ? 'Kategoriyi Düzenle' : 'Yeni Kategori'}
                </h3>
                <button 
                  onClick={() => {
                    setIsFormOpen(false);
                    setSelectedCategory(null);
                  }}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <CategoryForm 
                category={selectedCategory || undefined}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}