import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { blogService } from '../services/blogService';
import type { BlogPost } from '../types/blog';
import { 
  Clock, 
  User, 
  Tag,
  Folder,
  ArrowLeft
} from 'lucide-react';

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!slug) throw new Error('Post slug is required');
        const data = await blogService.getPostBySlug(slug);
        setPost(data);
      } catch (err) {
        setError('Blog yazısı yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <Navbar />
        <div className="pt-32 pb-24">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="animate-pulse space-y-8">
              <div className="h-8 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              <div className="h-96 bg-gray-200 rounded"></div>
              <div className="space-y-4">
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-white">
        <Navbar />
        <div className="pt-32 pb-24">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
              {error || 'Blog yazısı bulunamadı'}
            </h1>
            <Link 
              to="/blog"
              className="inline-flex items-center text-[#4ADE80] hover:text-[#FF6B00] transition-colors"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Blog'a Dön
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      <div className="pt-32 pb-24">
        <article className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <header className="mb-12">
            <Link 
              to="/blog"
              className="inline-flex items-center text-gray-500 hover:text-[#4ADE80] transition-colors mb-8"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Blog'a Dön
            </Link>

            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              {post.title}
            </h1>

            <div className="flex flex-wrap items-center gap-6 text-sm text-gray-500">
              <div className="flex items-center gap-2">
                <Clock className="h-4 w-4" />
                {new Date(post.created_at).toLocaleDateString('tr-TR')}
              </div>

              {post.categories?.map(category => (
                <Link
                  key={category.id}
                  to={`/blog?category=${category.slug}`}
                  className="flex items-center gap-2 text-[#4ADE80] hover:text-[#FF6B00] transition-colors"
                >
                  <Folder className="h-4 w-4" />
                  {category.name}
                </Link>
              ))}
            </div>
          </header>

          {/* Featured Image */}
          {post.featured_image && (
            <div className="mb-12">
              <img
                src={post.featured_image}
                alt={post.title}
                className="w-full h-[400px] object-cover rounded-2xl"
              />
            </div>
          )}

          {/* Content */}
          <div 
            className="prose prose-lg max-w-none mb-12"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          {/* Tags */}
          {post.tags && post.tags.length > 0 && (
            <div className="border-t border-gray-200 pt-8">
              <div className="flex items-center gap-4">
                <Tag className="h-5 w-5 text-gray-400" />
                <div className="flex flex-wrap gap-2">
                  {post.tags.map(tag => (
                    <Link
                      key={tag.id}
                      to={`/blog?tag=${tag.slug}`}
                      className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium hover:bg-[#4ADE80] hover:text-white transition-all duration-300"
                    >
                      {tag.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </article>
      </div>

      <Footer />
    </div>
  );
}