import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import Breadcrumb from '../components/Breadcrumb';
import LocalBusiness from '../components/LocalBusiness';
import ImageSEO from '../components/ImageSEO';
import SEOAnalyzer from '../components/SEOAnalyzer';
import { 
  BarChart, 
  Target, 
  Users, 
  Mail, 
  Megaphone,
  Zap,
  ArrowRight,
  Briefcase
} from 'lucide-react';

function DigitalMarketing() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const pageContent = `
    Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, 
    hedef kitlenize ulaşmanızı sağlıyoruz. Sosyal medya yönetimi, Google Ads, Facebook & Instagram Ads, 
    LinkedIn Ads, TikTok Ads ve daha fazlası için profesyonel dijital pazarlama hizmetleri sunuyoruz.
  `;

  const breadcrumbItems = [
    {
      name: 'Hizmetler',
      path: '/hizmetler',
      icon: Briefcase
    },
    {
      name: 'Dijital Pazarlama',
      path: '/dijital-pazarlama',
      icon: BarChart
    }
  ];

  return (
    <>
      <Helmet>
        <title>Dijital Pazarlama Hizmetleri | AjansPixel</title>
        <meta name="description" content="Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz." />
        <meta name="keywords" content="dijital pazarlama, online pazarlama, sosyal medya yönetimi, google ads, facebook ads" />
        <link rel="canonical" href="https://ajanspixel.com/dijital-pazarlama" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Dijital Pazarlama Hizmetleri | AjansPixel" />
        <meta property="og:description" content="Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz." />
        <meta property="og:image" content="https://ajanspixel.com/images/services/digital-marketing.jpg" />
        <meta property="og:url" content="https://ajanspixel.com/dijital-pazarlama" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dijital Pazarlama Hizmetleri | AjansPixel" />
        <meta name="twitter:description" content="Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz." />
        <meta name="twitter:image" content="https://ajanspixel.com/images/services/digital-marketing.jpg" />
      </Helmet>

      <LocalBusiness />
      <Navbar />
      <Breadcrumb items={breadcrumbItems} />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-30" />
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-20" />
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-10" />
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">200+</span>
                    <span className="text-gray-600 font-medium">Başarılı Kampanya</span>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                Etkili
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">Dijital Pazarlama</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">Stratejileri</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz.
              </p>
              
              <div className="mt-12 flex items-center gap-6">
                <button 
                  onClick={() => setIsContactFormOpen(true)}
                  className="px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 inline-flex items-center gap-2"
                >
                  Hemen Başlayın
                  <Zap className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <ImageSEO 
                src="https://images.unsplash.com/photo-1552581234-26160f608093?w=800&h=800&fit=crop"
                alt="Dijital Pazarlama Stratejileri"
                title="Etkili Dijital Pazarlama Çözümleri"
                caption="Modern ve veri odaklı dijital pazarlama stratejileri"
                width={800}
                height={800}
                className="relative z-10 w-full h-[600px] rounded-[40px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Özellikler ve Hizmetler
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Dijital pazarlama stratejileriniz için kapsamlı çözümler
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: Users,
                title: "Sosyal Medya Yönetimi",
                desc: "Profesyonel içerik üretimi ve topluluk yönetimi ile sosyal medya varlığınızı güçlendirin.",
                gradient: "from-[#E3F2FD] to-[#BBDEFB]"
              },
              {
                icon: Target,
                title: "İçerik Pazarlama",
                desc: "SEO uyumlu, değerli içerikler ile organik trafiğinizi artırın.",
                gradient: "from-[#FFF3E0] to-[#FFE0B2]"
              },
              {
                icon: Mail,
                title: "Email Marketing",
                desc: "Kişiselleştirilmiş e-posta kampanyaları ile müşterilerinizle sürekli iletişimde kalın.",
                gradient: "from-[#E8F5E9] to-[#C8E6C9]"
              },
              {
                icon: BarChart,
                title: "Google Ads Yönetimi",
                desc: "Hedefli reklamlar ile potansiyel müşterilerinize ulaşın ve dönüşüm oranlarınızı artırın.",
                gradient: "from-[#F3E5F5] to-[#E1BEE7]"
              }
            ].map((feature, index) => (
              <div key={index} className="group relative bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]">
                <div className={`w-16 h-16 mb-6 bg-gradient-to-br ${feature.gradient} rounded-2xl flex items-center justify-center`}>
                  <feature.icon className="h-8 w-8 text-[#4CAF50]" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Success Stories Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Başarı Hikayeleri
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Müşterilerimizin dijital başarı hikayeleri
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "E-ticaret Büyümesi",
                desc: "6 ayda %150 satış artışı ve %80 daha fazla organik trafik",
                image: "https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?w=800&h=500&fit=crop"
              },
              {
                title: "Marka Bilinirliği",
                desc: "3 ayda sosyal medya takipçi sayısında %200 artış",
                image: "https://images.unsplash.com/photo-1557838923-2985c318be48?w=800&h=500&fit=crop"
              },
              {
                title: "Lead Generation",
                desc: "Aylık 500+ yeni potansiyel müşteri kazanımı",
                image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=500&fit=crop"
              }
            ].map((story, index) => (
              <div key={index} className="group relative overflow-hidden rounded-2xl">
                <div className="relative h-64">
                  <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-70 transition-all duration-300"></div>
                  <img 
                    src={story.image} 
                    alt={story.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 p-6 flex flex-col justify-end">
                    <h3 className="text-xl font-bold text-white mb-2">{story.title}</h3>
                    <p className="text-gray-200">{story.desc}</p>
                    <a href="#" className="mt-4 inline-flex items-center text-[#4ADE80] font-medium">
                      Detayları İncele
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-3xl p-12 relative overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute -top-12 -left-12 w-72 h-72 bg-[#FF5733] rounded-full"></div>
              <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute -bottom-12 -right-12 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
            </div>
            
            <div className="relative text-center max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-6">Dijital Başarı Yolculuğunuza Başlayın</h2>
              <p className="text-lg text-gray-600 mb-8">
                Markanızı dijital dünyada büyütmek için hemen iletişime geçin.
              </p>
              <button 
                onClick={() => setIsContactFormOpen(true)}
                className="inline-flex items-center px-8 py-4 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 group"
              >
                Hemen İletişime Geçin
                <Zap className="ml-2 h-5 w-5 transform group-hover:-rotate-12 transition-transform duration-300" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />

      {process.env.NODE_ENV === 'development' && (
        <SEOAnalyzer 
          content={pageContent}
          title="Dijital Pazarlama Hizmetleri"
          description="Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz."
          keywords={['dijital pazarlama', 'online pazarlama', 'sosyal medya yönetimi', 'google ads']}
          url="/dijital-pazarlama"
        />
      )}
    </>
  );
}

export default DigitalMarketing;