import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { 
  Mail, 
  Phone, 
  MapPin, 
  Clock, 
  Send, 
  Loader2,
  Facebook,
  Instagram,
  Linkedin,
  Twitter
} from 'lucide-react';
import { usePageTitle } from '../hooks/usePageTitle';

function Contact() {
  usePageTitle('İletişim');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate form submission
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Reset form
    setFormData({ name: '', email: '', phone: '', subject: '', message: '' });
    setIsSubmitting(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      {/* Hero Section */}
      <div className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-5xl font-black mb-6">
              Bizimle
              <span className="text-[#4ADE80] ml-3">İletişime</span>
              <div className="relative inline-block ml-3">
                Geçin
                <div className="absolute left-0 -bottom-2 w-full">
                  <svg className="w-full" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.5C47.5 2.5 152.5 2.5 199 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </div>
              </div>
            </h1>
            <p className="text-xl text-gray-600 mt-8">
              Projeleriniz için bilgi almak veya işbirliği yapmak için bizimle iletişime geçebilirsiniz.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Info & Form Section */}
      <div className="pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Contact Info */}
            <div className="lg:col-span-1">
              <div className="bg-white rounded-2xl p-8 shadow-lg">
                <h2 className="text-2xl font-bold mb-6">İletişim Bilgileri</h2>
                
                <div className="space-y-6">
                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-lg bg-[#4ADE80] bg-opacity-10 flex items-center justify-center flex-shrink-0">
                      <Mail className="h-5 w-5 text-[#4ADE80]" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">E-posta</h3>
                      <a href="mailto:info@ajanspixel.com" className="text-gray-600 hover:text-[#4ADE80] transition-colors">
                        info@ajanspixel.com
                      </a>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-lg bg-[#4ADE80] bg-opacity-10 flex items-center justify-center flex-shrink-0">
                      <Phone className="h-5 w-5 text-[#4ADE80]" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Telefon</h3>
                      <a href="tel:+905375169044" className="text-gray-600 hover:text-[#4ADE80] transition-colors">
                        +90 537 516 90 44
                      </a>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-lg bg-[#4ADE80] bg-opacity-10 flex items-center justify-center flex-shrink-0">
                      <MapPin className="h-5 w-5 text-[#4ADE80]" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Adres</h3>
                      <p className="text-gray-600">
                        Küçükbakkalköy Mah. Kocasinan Cad. Selvili Sok, Canan Business Center No:4 K:10 D:68 34755 Ataşehir / İstanbul /Türkiye
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-lg bg-[#4ADE80] bg-opacity-10 flex items-center justify-center flex-shrink-0">
                      <Clock className="h-5 w-5 text-[#4ADE80]" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Çalışma Saatleri</h3>
                      <p className="text-gray-600">
                        Pazartesi - Cuma: 09:00 - 18:00
                      </p>
                    </div>
                  </div>
                </div>

                {/* Social Media Links */}
                <div className="mt-8 pt-6 border-t border-gray-100">
                  <h3 className="font-medium text-gray-900 mb-4">Sosyal Medya</h3>
                  <div className="flex space-x-4">
                    <a href="#" className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center text-gray-600 hover:bg-[#4ADE80] hover:text-white transition-all">
                      <Instagram className="h-5 w-5" />
                    </a>
                    <a href="#" className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center text-gray-600 hover:bg-[#4ADE80] hover:text-white transition-all">
                      <Twitter className="h-5 w-5" />
                    </a>
                    <a href="#" className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center text-gray-600 hover:bg-[#4ADE80] hover:text-white transition-all">
                      <Facebook className="h-5 w-5" />
                    </a>
                    <a href="#" className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center text-gray-600 hover:bg-[#4ADE80] hover:text-white transition-all">
                      <Linkedin className="h-5 w-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            <div className="lg:col-span-2">
              <div className="bg-white rounded-2xl p-8 shadow-lg">
                <h2 className="text-2xl font-bold mb-6">İletişim Formu</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                        Adınız Soyadınız
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent transition-all"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        E-posta Adresiniz
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent transition-all"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                        Telefon Numaranız
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        value={formData.phone}
                        onChange={e => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent transition-all"
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                        Konu
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={formData.subject}
                        onChange={e => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent transition-all"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                      Mesajınız
                    </label>
                    <textarea
                      id="message"
                      value={formData.message}
                      onChange={e => setFormData(prev => ({ ...prev, message: e.target.value }))}
                      rows={6}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent transition-all resize-none"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full px-6 py-3 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="h-5 w-5 animate-spin" />
                        Gönderiliyor...
                      </>
                    ) : (
                      <>
                        Gönder
                        <Send className="h-5 w-5" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* Map Section */}
          <div className="mt-12">
            <div className="bg-white rounded-2xl p-8 shadow-lg">
              <h2 className="text-2xl font-bold mb-6">Konum</h2>
              <div className="aspect-video rounded-xl overflow-hidden bg-gray-100 relative">
                <a 
                  href="https://www.google.com/maps?q=40.9897073,29.1218663" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block w-full h-full"
                >
                  <img 
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=40.9897073,29.1218663&zoom=15&size=1200x600&markers=color:red%7C40.9897073,29.1218663&key=YOUR_GOOGLE_MAPS_API_KEY`}
                    alt="AjansPixel Ofis Konumu"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 hover:bg-opacity-10 transition-all duration-300">
                    <span className="px-4 py-2 bg-white rounded-lg shadow-md text-gray-900 font-medium opacity-0 hover:opacity-100 transition-opacity duration-300">
                      Google Maps'te Aç
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;