import React, { useState, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import LocalBusiness from '../components/LocalBusiness';
import ImageSEO from '../components/ImageSEO';
import { 
  ChevronRight, 
  Clock,
  ArrowRight,
  Zap,
  Star,
  Award,
  Users,
  Target,
  Shield,
  CheckCircle
} from 'lucide-react';

const socialLogos = [
  {
    name: 'Meta Ads',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png',
    color: '#0668E1'
  },
  {
    name: 'Google Ads',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Google_Ads_logo.svg',
    color: '#4285F4'
  },
  {
    name: 'LinkedIn Ads',
    icon: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png',
    color: '#0A66C2'
  },
  {
    name: 'TikTok Ads',
    icon: 'https://upload.wikimedia.org/wikipedia/en/a/a9/TikTok_logo.svg',
    color: '#000000'
  }
];

const testimonials = [
  {
    name: "Ahmet Yılmaz",
    role: "E-ticaret Yöneticisi",
    company: "Fashion Store",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop",
    content: "AjansPixel ile çalışmaya başladıktan sonra online satışlarımız %200 arttı. SEO ve dijital pazarlama stratejileri sayesinde organik trafiğimiz sürekli yükseliyor.",
    rating: 5
  },
  {
    name: "Ayşe Kaya",
    role: "Pazarlama Direktörü",
    company: "Tech Solutions",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop",
    content: "Profesyonel ekip, yaratıcı çözümler ve sonuç odaklı yaklaşım. Marka bilinirliğimizi artırmada büyük katkıları oldu.",
    rating: 5
  },
  {
    name: "Mehmet Demir",
    role: "Genel Müdür",
    company: "Global Logistics",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop",
    content: "Kurumsal web sitemizin tasarımından sosyal medya yönetimine kadar tüm dijital varlığımızı başarıyla yönetiyorlar.",
    rating: 5
  }
];

const stats = [
  {
    number: "500+",
    label: "Mutlu Müşteri",
    icon: Users
  },
  {
    number: "50M+",
    label: "Reklam Bütçesi",
    icon: Target
  },
  {
    number: "100+",
    label: "Başarılı Proje",
    icon: CheckCircle
  },
  {
    number: "15+",
    label: "Yıllık Deneyim",
    icon: Award
  }
];

const certifications = [
  {
    name: "Google Partners",
    icon: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Google_Ads_logo.svg",
    description: "Premier Google Ads Partner"
  },
  {
    name: "Meta Business Partner",
    icon: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Meta-Logo.png",
    description: "Sertifikalı Meta İş Ortağı"
  },
  {
    name: "ISO 27001",
    icon: Shield,
    description: "Bilgi Güvenliği Sertifikası"
  }
];

const blogPosts = [
  {
    title: "Dijital Pazarlamada 2024 Trendleri",
    slug: "dijital-pazarlama-2024-trendleri",
    excerpt: "Yapay zeka, metaverse ve sürdürülebilir pazarlama stratejilerinin öne çıktığı 2024 dijital pazarlama trendlerini keşfedin.",
    image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=500&fit=crop",
    category: "Dijital Pazarlama",
    date: "15 Mart 2024",
    readTime: "5 dk okuma",
    relatedServices: ['/dijital-pazarlama', '/yapay-zeka-ve-otomasyon']
  },
  {
    title: "E-ticaret Başarı Hikayeleri",
    slug: "e-ticaret-basari-hikayeleri",
    excerpt: "Küçük işletmelerden global markalara: Başarılı e-ticaret dönüşüm hikayeleri ve öğrenilen dersler.",
    image: "https://images.unsplash.com/photo-1556742049-0cfed4f6a45d?w=800&h=500&fit=crop",
    category: "E-ticaret",
    date: "12 Mart 2024",
    readTime: "4 dk okuma",
    relatedServices: ['/e-ticaret-cozumleri', '/donusum-optimizasyonu']
  },
  {
    title: "SEO Optimizasyonu İpuçları",
    slug: "seo-optimizasyonu-ipuclari",
    excerpt: "Google algoritma güncellemeleri ışığında 2024 için en etkili SEO stratejileri ve uygulama önerileri.",
    image: "https://images.unsplash.com/photo-1499750310107-5fef28a66643?w=800&h=500&fit=crop",
    category: "SEO",
    date: "10 Mart 2024",
    readTime: "6 dk okuma",
    relatedServices: ['/seo-ve-icerik-optimizasyonu', '/icerik-pazarlama']
  }
];

const TestimonialImage = memo(({ image, name, index }: { 
  image: string; 
  name: string; 
  index: number; 
}) => (
  <img 
    src={image} 
    alt={name} 
    className="w-12 h-12 rounded-full border-2 border-white relative"
    style={{ zIndex: 30 - index * 10 }}
  />
));

const SocialLogo = memo(({ logo }: { 
  logo: { 
    name: string; 
    icon: string; 
    color: string; 
  }; 
}) => (
  <div 
    className="w-12 h-12 flex items-center justify-center transform hover:scale-110 transition-transform duration-300"
    style={{ color: logo.color }}
  >
    <img 
      src={logo.icon} 
      alt={logo.name}
      className="w-12 h-12 object-contain drop-shadow-md"
    />
  </div>
));

const BlogCard = memo(({ post }: { 
  post: {
    title: string;
    slug: string;
    excerpt: string;
    image: string;
    category: string;
    date: string;
    readTime: string;
    relatedServices: string[];
  }; 
}) => (
  <article className="blog-card group">
    <div className="blog-image-container">
      <ImageSEO 
        src={post.image}
        alt={post.title}
        className="blog-image"
        width={800}
        height={500}
        title={post.title}
        caption={post.excerpt}
      />
      <div className="absolute top-4 left-4">
        <span className="blog-category">
          {post.category}
        </span>
      </div>
    </div>
    
    <div className="blog-content">
      <div className="blog-meta">
        <Clock className="h-4 w-4 mr-2" />
        <span>{post.date}</span>
        <span className="mx-2">•</span>
        <span>{post.readTime}</span>
      </div>
      
      <Link to={`/blog/${post.slug}`}>
        <h3 className="blog-title">
          {post.title}
        </h3>
        
        <p className="blog-excerpt">
          {post.excerpt}
        </p>
        
        <div className="mt-4 space-y-2">
          <div className="flex items-center text-[#4ADE80] group-hover:text-[#FF6B00] transition-colors">
            <span className="font-medium">Devamını Oku</span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </div>
        </div>
      </Link>

      <div className="flex flex-wrap gap-2 mt-4">
        {post.relatedServices.map((service, idx) => (
          <Link 
            key={idx}
            to={service}
            className="text-sm text-gray-500 hover:text-[#4ADE80] transition-colors"
            onClick={(e) => e.stopPropagation()}
          >
            #{service.replace('/', '').replace(/-/g, ' ')}
          </Link>
        ))}
      </div>
    </div>
  </article>
));

const Home = memo(() => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>AjansPixel - Dijital Ajans | Web Tasarım, SEO ve Dijital Pazarlama</title>
        <meta name="description" content="Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz. Web tasarım, SEO, dijital pazarlama ve sosyal medya yönetimi hizmetleriyle işletmenizi büyütün." />
        <meta name="keywords" content="dijital ajans, web tasarım, seo, dijital pazarlama, sosyal medya yönetimi, marka kimliği, e-ticaret, mobil uygulama, istanbul dijital ajans" />
        <link rel="canonical" href="https://ajanspixel.com" />
        
        {/* Open Graph */}
        <meta property="og:title" content="AjansPixel - Dijital Ajans | Web Tasarım, SEO ve Dijital Pazarlama" />
        <meta property="og:description" content="Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz." />
        <meta property="og:image" content="https://ajanspixel.com/og-image.jpg" />
        <meta property="og:url" content="https://ajanspixel.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="AjansPixel" />
        <meta property="og:locale" content="tr_TR" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ajanspixel" />
        <meta name="twitter:creator" content="@ajanspixel" />
        <meta name="twitter:title" content="AjansPixel - Dijital Ajans | Web Tasarım, SEO ve Dijital Pazarlama" />
        <meta name="twitter:description" content="Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz." />
        <meta name="twitter:image" content="https://ajanspixel.com/og-image.jpg" />
      </Helmet>

      <LocalBusiness />
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    {testimonials.map((testimonial, index) => (
                      <TestimonialImage 
                        key={index}
                        image={testimonial.image}
                        name={testimonial.name}
                        index={index}
                      />
                    ))}
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      500+ Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">4.9</span>
                    <div className="flex items-center">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star key={star} className="h-5 w-5 text-[#FFD700] fill-current" />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                İşinizi
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">Dijital Pazarlama</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">ile Büyütün</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                İşletmenizi dijital dünyada bir adım öne taşıyoruz. SEO, dijital reklam ve sistem çözümleriyle yanınızdayız.
              </p>
              
              <div className="mt-12 space-y-6">
                <div className="flex flex-col sm:flex-row items-center gap-6">
                  <button 
                    onClick={() => setIsContactFormOpen(true)}
                    className="w-full sm:w-auto px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 flex items-center justify-center gap-2"
                  >
                    Hemen Başlayın
                    <Zap className="h-5 w-5" />
                  </button>
                  <div className="relative bg-white shadow-lg rounded-2xl p-4 w-full sm:w-auto">
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-gray-50 rounded-2xl"></div>
                    <div className="relative flex items-center gap-8">
                      {socialLogos.map((logo, index) => (
                        <SocialLogo key={index} logo={logo} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="relative">
              <ImageSEO 
                src="https://images.unsplash.com/photo-1552581234-26160f608093?w=800&h=800&fit=crop"
                alt="Dijital Pazarlama Ekibi"
                title="AjansPixel Dijital Ajans Ekibi"
                caption="Modern ve yaratıcı dijital çözümler sunan profesyonel ekibimiz"
                width={800}
                height={800}
                className="relative z-10 w-full h-[600px] rounded-[40px]"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative bg-white border-y border-gray-100">
        <div className="absolute inset-0 overflow-hidden">
          <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute top-0 left-0 w-72 h-72 bg-[#FF5733] rounded-full"></div>
          <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute bottom-0 right-0 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
        </div>
        <div className="relative max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="text-center mb-12">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">Rakamlarla Biz</h2>
              <div className="absolute left-0 -bottom-4 w-full">
                <svg className="w-full" height="12" viewBox="0 0 240 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 9C60.5 3 179.5 3 239 9" stroke="#FF6B00" strokeWidth="3" strokeLinecap="round"/>
                </svg>
              </div>
            </div>
            <p className="mt-8 text-lg text-gray-600">Başarı hikayemizi rakamlarla anlatıyoruz</p>
          </div>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <div key={index} className="text-center">
                  <div className="inline-flex items-center justify-center w-16 h-16 mb-4 rounded-2xl bg-[#4ADE80] bg-opacity-10">
                    <Icon className="w-8 h-8 text-[#4ADE80]" />
                  </div>
                  <div className="text-5xl font-extrabold bg-gradient-to-r from-[#FF5733] to-[#2B4EFF] bg-clip-text text-transparent">
                    {stat.number}
                  </div>
                  <div className="mt-2 text-sm font-medium text-gray-600 max-w-[200px] mx-auto">
                    {stat.label}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Certifications Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900">
              Sertifikalarımız ve Ortaklıklarımız
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Güvenilir iş ortaklarınız olarak yanınızdayız
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {certifications.map((cert, index) => (
              <div 
                key={index}
                className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center"
              >
                {typeof cert.icon === 'string' ? (
                  <img 
                    src={cert.icon} 
                    alt={cert.name}
                    className="w-16 h-16 mb-4 object-contain"
                  />
                ) : (
                  <cert.icon className="w-16 h-16 mb-4 text-[#4ADE80]" />
                )}
                <h3 className="text-xl font-bold text-gray-900 mb-2">{cert.name}</h3>
                <p className="text-gray-600">{cert.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Blog Section */}
      <section aria-labelledby="blog-heading" className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 id="blog-heading" className="text-4xl font-bold text-gray-900">
              Blog
            </h2>
            <p className="mt-8 text-lg text-gray-600">
              Dijital dünyadan en güncel içerikler ve uzman görüşleri
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogPosts.map((post, index) => (
              <BlogCard key={index} post={post} />
            ))}
          </div>

          <div className="text-center mt-12">
            <Link 
              to="/blog" 
              className="inline-flex items-center px-6 py-3 bg-white border-2 border-[#4ADE80] text-[#4ADE80] font-medium rounded-xl hover:bg-[#4ADE80] hover:text-white transition-all duration-300"
            >
              Tüm Blog Yazıları
              <ChevronRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </section>

      <Footer />

      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </>
  );
});

export default Home;