import React from 'react';
import { Globe } from 'lucide-react';

interface Language {
  code: string;
  name: string;
  flag: string;
}

const languages: Language[] = [
  {
    code: 'tr',
    name: 'Türkçe',
    flag: '🇹🇷'
  },
  {
    code: 'en',
    name: 'English',
    flag: '🇬🇧'
  }
];

function LanguageSelector() {
  const currentLang = 'tr'; // Default to Turkish

  const handleLanguageChange = (langCode: string) => {
    if (langCode === 'en') {
      window.location.href = `https://ajanspixel.com/en${window.location.pathname}`;
    } else {
      window.location.href = `https://ajanspixel.com${window.location.pathname.replace('/en', '')}`;
    }
  };

  return (
    <div className="relative group">
      <button 
        className="flex items-center gap-2 px-3 py-2 text-gray-600 hover:text-[#4ADE80] transition-colors"
        aria-label="Dil Seçimi"
      >
        <Globe className="h-5 w-5" />
        <span className="hidden sm:inline">{languages.find(lang => lang.code === currentLang)?.name}</span>
      </button>

      <div className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-gray-100 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
        {languages.map(lang => (
          <button
            key={lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            className={`w-full flex items-center gap-3 px-4 py-2 text-left hover:bg-gray-50 transition-colors ${
              currentLang === lang.code ? 'text-[#4ADE80] font-medium' : 'text-gray-600'
            } ${lang.code === 'tr' ? 'rounded-t-xl' : 'rounded-b-xl'}`}
          >
            <span className="text-xl">{lang.flag}</span>
            <span>{lang.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default LanguageSelector;