import React from 'react';

function LocalBusiness() {
  const localBusinessData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    '@id': 'https://ajanspixel.com',
    'name': 'AjansPixel',
    'alternateName': 'AjansPixel Dijital Ajans',
    'description': 'Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz.',
    'url': 'https://ajanspixel.com',
    'logo': 'https://ajanspixel.com/logo.png',
    'image': [
      'https://ajanspixel.com/og-image.jpg',
      'https://ajanspixel.com/logo.png'
    ],
    'telephone': '+905375169044',
    'email': 'info@ajanspixel.com',
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': 'Küçükbakkalköy Mah. Kocasinan Cad. Selvili Sok, Canan Business Center No:4 K:10 D:68',
      'addressLocality': 'Ataşehir',
      'addressRegion': 'İstanbul',
      'postalCode': '34755',
      'addressCountry': 'TR'
    },
    'geo': {
      '@type': 'GeoCoordinates',
      'latitude': '40.9897073',
      'longitude': '29.1218663'
    },
    'openingHoursSpecification': [
      {
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday'
        ],
        'opens': '09:00',
        'closes': '18:00'
      }
    ],
    'priceRange': '₺₺₺',
    'currenciesAccepted': 'TRY',
    'paymentAccepted': 'Cash, Credit Card',
    'areaServed': {
      '@type': 'GeoCircle',
      'geoMidpoint': {
        '@type': 'GeoCoordinates',
        'latitude': '40.9897073',
        'longitude': '29.1218663'
      },
      'geoRadius': '50000'
    },
    'hasMap': 'https://goo.gl/maps/your-google-maps-url',
    'sameAs': [
      'https://www.facebook.com/ajanspixel',
      'https://www.instagram.com/ajanspixel',
      'https://www.linkedin.com/company/ajanspixel',
      'https://twitter.com/ajanspixel'
    ]
  };

  return (
    <script 
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(localBusinessData) }}
    />
  );
}

export default LocalBusiness;