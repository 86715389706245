import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';

interface BreadcrumbItem {
  name: string;
  path: string;
  icon?: React.ElementType;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

function Breadcrumb({ items }: BreadcrumbProps) {
  // Breadcrumb verilerini oluştur
  const breadcrumbData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        '@type': 'ListItem',
        'position': 1,
        'name': 'Ana Sayfa',
        'item': 'https://ajanspixel.com'
      },
      ...items.map((item, index) => ({
        '@type': 'ListItem',
        'position': index + 2,
        'name': item.name,
        'item': `https://ajanspixel.com${item.path}`
      }))
    ]
  };

  return (
    <>
      {/* Schema.org verisi */}
      <script 
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbData) }}
      />

      {/* Breadcrumb navigasyonu */}
      <nav 
        aria-label="Breadcrumb"
        className="bg-gradient-to-r from-gray-50 to-white py-4 mt-20 border-b border-gray-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ol 
            className="flex items-center flex-wrap gap-2" 
            itemScope 
            itemType="https://schema.org/BreadcrumbList"
          >
            {/* Ana Sayfa */}
            <li 
              itemProp="itemListElement" 
              itemScope 
              itemType="https://schema.org/ListItem"
              className="flex items-center"
            >
              <Link 
                to="/" 
                className="flex items-center px-3 py-2 text-gray-500 hover:text-[#4ADE80] hover:bg-[#4ADE80] hover:bg-opacity-5 rounded-lg transition-all duration-300"
                itemProp="item"
                title="Ana Sayfa"
              >
                <Home className="h-4 w-4" />
                <span className="sr-only" itemProp="name">Ana Sayfa</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>

            {/* Dinamik öğeler */}
            {items.map((item, index) => {
              const Icon = item.icon;
              const isLast = index === items.length - 1;

              return (
                <li 
                  key={item.path}
                  className="flex items-center"
                  itemProp="itemListElement" 
                  itemScope 
                  itemType="https://schema.org/ListItem"
                >
                  <ChevronRight className="h-4 w-4 text-gray-400 flex-shrink-0 mx-1" aria-hidden="true" />
                  
                  {isLast ? (
                    <div 
                      className="flex items-center px-4 py-2 bg-[#4ADE80] bg-opacity-10 rounded-lg"
                      aria-current="page"
                    >
                      {Icon && <Icon className="h-4 w-4 text-[#4ADE80] mr-2" aria-hidden="true" />}
                      <span 
                        className="text-[#4ADE80] font-medium"
                        itemProp="name"
                      >
                        {item.name}
                      </span>
                      <link itemProp="item" href={`https://ajanspixel.com${item.path}`} />
                    </div>
                  ) : (
                    <Link
                      to={item.path}
                      className="flex items-center px-4 py-2 text-gray-600 hover:text-[#4ADE80] hover:bg-[#4ADE80] hover:bg-opacity-5 rounded-lg transition-all duration-300"
                      itemProp="item"
                      title={item.name}
                    >
                      {Icon && <Icon className="h-4 w-4 mr-2" aria-hidden="true" />}
                      <span itemProp="name">{item.name}</span>
                    </Link>
                  )}
                  <meta itemProp="position" content={`${index + 2}`} />
                </li>
              );
            })}
          </ol>
        </div>
      </nav>
    </>
  );
}

export default Breadcrumb;