import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { 
  Users, 
  MessageSquare, 
  Search, 
  Bot,
  ArrowUpRight
} from 'lucide-react';

function Dashboard() {
  const stats = [
    {
      name: 'Form Yanıtları',
      value: '24',
      change: '+4.75%',
      changeType: 'positive',
      icon: MessageSquare,
      iconBg: 'bg-blue-500'
    },
    {
      name: 'Arama Motoru Botları',
      value: '156',
      change: '+54.02%',
      changeType: 'positive',
      icon: Search,
      iconBg: 'bg-green-500'
    },
    {
      name: 'Yapay Zeka Botları',
      value: '89',
      change: '+12.30%',
      changeType: 'positive',
      icon: Bot,
      iconBg: 'bg-purple-500'
    }
  ];

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
          <p className="mt-2 text-sm text-gray-600">
            Hoş geldiniz! İşte sitenizin genel durumu.
          </p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((stat) => {
            const Icon = stat.icon;
            return (
              <div
                key={stat.name}
                className="relative bg-white p-6 rounded-2xl shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                <div className="flex items-center">
                  <div className={`p-3 rounded-xl ${stat.iconBg}`}>
                    <Icon className="h-6 w-6 text-white" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-500">{stat.name}</h3>
                    <div className="flex items-baseline">
                      <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
                      <p className={`ml-2 text-sm ${stat.changeType === 'positive' ? 'text-green-600' : 'text-red-600'}`}>
                        {stat.change}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Recent Activity */}
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">Son Aktiviteler</h3>
          </div>
          <div className="divide-y divide-gray-200">
            {[
              {
                title: 'Yeni form yanıtı',
                description: 'info@example.com adresinden yeni bir form yanıtı geldi.',
                time: '5 dakika önce',
                icon: MessageSquare,
                iconBg: 'bg-blue-500'
              },
              {
                title: 'Google bot ziyareti',
                description: '/hizmetler sayfası tarandı',
                time: '15 dakika önce',
                icon: Search,
                iconBg: 'bg-green-500'
              },
              {
                title: 'ChatGPT bot ziyareti',
                description: '/blog sayfası analiz edildi',
                time: '1 saat önce',
                icon: Bot,
                iconBg: 'bg-purple-500'
              }
            ].map((activity, index) => {
              const Icon = activity.icon;
              return (
                <div key={index} className="px-6 py-4 hover:bg-gray-50 transition-colors duration-300">
                  <div className="flex items-center">
                    <div className={`p-2 rounded-lg ${activity.iconBg}`}>
                      <Icon className="h-5 w-5 text-white" />
                    </div>
                    <div className="ml-4 flex-1">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">{activity.title}</p>
                        <span className="text-sm text-gray-500">{activity.time}</span>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{activity.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default Dashboard;