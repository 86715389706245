import React from 'react';

interface ImageSEOProps {
  src: string;
  alt: string;
  title?: string;
  caption?: string;
  width?: number;
  height?: number;
  className?: string;
  loading?: 'lazy' | 'eager';
}

function ImageSEO({
  src,
  alt,
  title,
  caption,
  width,
  height,
  className,
  loading = 'lazy'
}: ImageSEOProps) {
  // Generate WebP URL for Unsplash images
  const webpSrc = src.includes('unsplash.com') 
    ? `${src}&fm=webp` 
    : src.replace(/\.(jpg|jpeg|png)$/, '.webp');

  // Generate srcSet for responsive images
  const generateSrcSet = (imgSrc: string) => {
    const widths = [320, 640, 768, 1024, 1280, 1536];
    return widths
      .map(w => `${imgSrc}?w=${w}&q=75 ${w}w`)
      .join(', ');
  };

  // Schema.org data for the image
  const imageSchema = {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    contentUrl: src,
    name: title || alt,
    caption: caption || alt,
    width: width,
    height: height
  };

  return (
    <>
      <script 
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(imageSchema) }}
      />
      <figure className={className}>
        <picture>
          <source
            srcSet={generateSrcSet(webpSrc)}
            type="image/webp"
          />
          <source
            srcSet={generateSrcSet(src)}
            type={`image/${src.split('.').pop()}`}
          />
          <img
            src={src}
            alt={alt}
            title={title}
            width={width}
            height={height}
            loading={loading}
            className="w-full h-full object-cover"
          />
        </picture>
        {caption && (
          <figcaption className="mt-2 text-sm text-gray-600">
            {caption}
          </figcaption>
        )}
      </figure>
    </>
  );
}

export default ImageSEO;