import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import { usePageTitle } from '../hooks/usePageTitle';
import { 
  Linkedin,
  Twitter,
  Mail,
  Users,
  Target,
  Award,
  Star,
  Heart,
  Zap,
  Briefcase,
  GraduationCap,
  Coffee
} from 'lucide-react';

function Team() {
  usePageTitle('Ekibimiz');
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const teamMembers = [
    {
      id: 1,
      name: 'Oğuzhan Pişgin',
      role: 'CEO & Kurucu',
      image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?w=400&h=400&fit=crop',
      bio: '25+ yıllık dijital pazarlama ve teknoloji deneyimi',
      expertise: ['Dijital Strateji', 'İş Geliştirme', 'Liderlik'],
      social: {
        linkedin: '#',
        twitter: '#',
        email: 'oguzhan@ajanspixel.com'
      }
    },
    {
      id: 2,
      name: 'Ayşe Kaya',
      role: 'Kreatif Direktör',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400&h=400&fit=crop',
      bio: '10+ yıllık tasarım ve kullanıcı deneyimi uzmanlığı',
      expertise: ['UI/UX Tasarım', 'Marka Kimliği', 'Görsel Tasarım'],
      social: {
        linkedin: '#',
        twitter: '#',
        email: 'ayse@ajanspixel.com'
      }
    },
    {
      id: 3,
      name: 'Mehmet Demir',
      role: 'Teknik Direktör',
      image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400&h=400&fit=crop',
      bio: '12+ yıllık yazılım geliştirme ve teknik liderlik deneyimi',
      expertise: ['Web Geliştirme', 'Mobil Uygulama', 'Cloud Teknolojileri'],
      social: {
        linkedin: '#',
        twitter: '#',
        email: 'mehmet@ajanspixel.com'
      }
    }
  ];

  const values = [
    {
      icon: Target,
      title: 'Müşteri Odaklılık',
      description: 'Müşterilerimizin başarısı için tutkuyla çalışıyoruz.'
    },
    {
      icon: Heart,
      title: 'Tutku',
      description: 'İşimizi severek yapıyor, en iyisi için çabalıyoruz.'
    },
    {
      icon: Users,
      title: 'Takım Ruhu',
      description: 'Birlikte daha güçlü, daha yaratıcı olduğumuza inanıyoruz.'
    },
    {
      icon: Award,
      title: 'Mükemmellik',
      description: 'Her projede en yüksek kaliteyi hedefliyoruz.'
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <div className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-5xl font-black mb-6">
              Yaratıcı
              <span className="text-[#4ADE80] ml-3">Ekibimiz</span>
              <div className="relative inline-block ml-3">
                ile Tanışın
                <div className="absolute left-0 -bottom-2 w-full">
                  <svg className="w-full" height="8" viewBox="0 0 200 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.5C47.5 2.5 152.5 2.5 199 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </div>
              </div>
            </h1>
            
            <p className="text-xl text-gray-600 mt-8">
              Tutkulu, yaratıcı ve deneyimli ekibimizle dijital dünyada fark yaratıyoruz.
              Her projede en iyisini hedefliyor, müşterilerimizin başarısı için çalışıyoruz.
            </p>
          </div>
        </div>
      </div>

      {/* Team Members Grid */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {teamMembers.map(member => (
              <div 
                key={member.id}
                className="group relative bg-white rounded-2xl p-6 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]"
              >
                <div className="relative mb-6">
                  <img 
                    src={member.image} 
                    alt={member.name}
                    className="w-full h-64 object-cover rounded-xl"
                  />
                  <div className="absolute top-4 right-4 flex space-x-2">
                    <a 
                      href={member.social.linkedin}
                      className="p-2 bg-white rounded-lg shadow-md hover:bg-[#4ADE80] hover:text-white transition-all duration-300"
                    >
                      <Linkedin className="h-5 w-5" />
                    </a>
                    <a 
                      href={member.social.twitter}
                      className="p-2 bg-white rounded-lg shadow-md hover:bg-[#4ADE80] hover:text-white transition-all duration-300"
                    >
                      <Twitter className="h-5 w-5" />
                    </a>
                    <a 
                      href={`mailto:${member.social.email}`}
                      className="p-2 bg-white rounded-lg shadow-md hover:bg-[#4ADE80] hover:text-white transition-all duration-300"
                    >
                      <Mail className="h-5 w-5" />
                    </a>
                  </div>
                </div>

                <h3 className="text-xl font-bold text-gray-900 mb-1">{member.name}</h3>
                <p className="text-[#4ADE80] font-medium mb-3">{member.role}</p>
                <p className="text-gray-600 mb-4">{member.bio}</p>

                <div className="space-y-2">
                  {member.expertise.map((skill, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Star className="h-4 w-4 text-[#FFD700]" />
                      <span className="text-gray-600">{skill}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Company Values */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Değerlerimiz</h2>
            <p className="text-xl text-gray-600">
              Bizi biz yapan temel değerler ve çalışma prensipleri
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => {
              const Icon = value.icon;
              return (
                <div 
                  key={index}
                  className="bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80] text-center"
                >
                  <div className="w-16 h-16 bg-[#4ADE80] bg-opacity-10 rounded-2xl flex items-center justify-center mx-auto mb-6">
                    <Icon className="h-8 w-8 text-[#4ADE80]" />
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-3">{value.title}</h3>
                  <p className="text-gray-600">{value.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Join Team CTA */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-3xl p-12 relative overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute -top-12 -left-12 w-72 h-72 bg-[#FF5733] rounded-full"></div>
              <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute -bottom-12 -right-12 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
            </div>
            
            <div className="relative text-center max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-6">Ekibimize Katılın</h2>
              <p className="text-lg text-gray-600 mb-8">
                Yaratıcı ve dinamik ekibimizin bir parçası olmak ister misiniz?
              </p>
              <button 
                onClick={() => setIsContactFormOpen(true)}
                className="inline-flex items-center px-8 py-4 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 group"
              >
                İletişime Geçin
                <Zap className="ml-2 h-5 w-5 transform group-hover:-rotate-12 transition-transform duration-300" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </div>
  );
}

export default Team;