import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Phone, 
  Mail, 
  MapPin, 
  ArrowUpRight,
  Instagram,
  Twitter as TwitterIcon,
  Facebook,
  Linkedin,
  Heart
} from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* About */}
          <div className="col-span-1">
            <Link to="/" className="flex flex-col items-start">
              <span className="text-3xl logo-text">
                <span className="text-[#F39200]">Ajans</span>
                <span className="text-[#2196F3]">Pixel</span>
              </span>
              <span className="text-sm text-gray-400 mt-1">Digital Agency</span>
            </Link>
            <p className="mt-4 text-gray-400">
              Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz.
            </p>
            <div className="mt-6 flex space-x-4">
              <a 
                href="#" 
                className="w-10 h-10 rounded-lg bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-[#4ADE80] hover:text-white transition-all"
              >
                <Instagram className="h-5 w-5" />
              </a>
              <a 
                href="#" 
                className="w-10 h-10 rounded-lg bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-[#4ADE80] hover:text-white transition-all"
              >
                <TwitterIcon className="h-5 w-5" />
              </a>
              <a 
                href="#" 
                className="w-10 h-10 rounded-lg bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-[#4ADE80] hover:text-white transition-all"
              >
                <Facebook className="h-5 w-5" />
              </a>
              <a 
                href="#" 
                className="w-10 h-10 rounded-lg bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-[#4ADE80] hover:text-white transition-all"
              >
                <Linkedin className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">Hızlı Bağlantılar</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Ana Sayfa
                </Link>
              </li>
              <li>
                <Link to="/hizmetler" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Hizmetler
                </Link>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Portfolyo
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Blog
                </a>
              </li>
              <li>
                <Link to="/iletisim" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  İletişim
                </Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">Hizmetlerimiz</h3>
            <ul className="space-y-4">
              <li>
                <Link to="/web-tasarim" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Web Tasarım
                </Link>
              </li>
              <li>
                <Link to="/dijital-pazarlama" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Dijital Pazarlama
                </Link>
              </li>
              <li>
                <Link to="/seo-optimizasyonu" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  SEO Optimizasyonu
                </Link>
              </li>
              <li>
                <Link to="/sosyal-medya-yonetimi" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Sosyal Medya Yönetimi
                </Link>
              </li>
              <li>
                <Link to="/marka-kimligi" className="text-gray-400 hover:text-[#4ADE80] transition-colors flex items-center gap-2">
                  <ArrowUpRight className="h-4 w-4" />
                  Marka Kimliği
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4">İletişim</h3>
            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <MapPin className="h-5 w-5 text-[#4ADE80] flex-shrink-0 mt-1" />
                <span className="text-gray-400">
                  Küçükbakkalköy Mah. Kocasinan Cad. Selvili Sok, Canan Business Center No:4 K:10 D:68 34755 Ataşehir / İstanbul
                </span>
              </li>
              <li className="flex items-center gap-3">
                <Phone className="h-5 w-5 text-[#4ADE80]" />
                <a href="tel:+905375169044" className="text-gray-400 hover:text-[#4ADE80] transition-colors">
                  +90 537 516 90 44
                </a>
              </li>
              <li className="flex items-center gap-3">
                <Mail className="h-5 w-5 text-[#4ADE80]" />
                <a href="mailto:info@ajanspixel.com" className="text-gray-400 hover:text-[#4ADE80] transition-colors">
                  info@ajanspixel.com
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm">
              &copy; 2024 AjansPixel. Tüm hakları saklıdır.
            </p>
            <div className="flex items-center gap-4">
              <a href="#" className="text-gray-400 hover:text-[#4ADE80] transition-colors text-sm">
                Gizlilik Politikası
              </a>
              <span className="text-gray-600">•</span>
              <a href="#" className="text-gray-400 hover:text-[#4ADE80] transition-colors text-sm">
                Kullanım Koşulları
              </a>
              <span className="text-gray-600">•</span>
              <a href="#" className="text-gray-400 hover:text-[#4ADE80] transition-colors text-sm flex items-center gap-1">
                <Heart className="h-4 w-4" />
                Made with love
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;