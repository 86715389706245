import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './components/ScrollToTop';
import { useAnalytics } from './hooks/useAnalytics';
import { useEffect } from 'react';
import Hizmetler from './pages/Hizmetler';
import Home from './pages/Home';
import WebDesign from './pages/WebDesign';
import DigitalMarketing from './pages/DigitalMarketing';
import SeoOptimization from './pages/SeoOptimization';
import SocialMediaManagement from './pages/SocialMediaManagement';
import BrandIdentity from './pages/BrandIdentity';
import ConversionOptimization from './pages/ConversionOptimization';
import WebDevelopment from './pages/WebDevelopment';
import EcommerceSolutions from './pages/EcommerceSolutions';
import MobileAppDevelopment from './pages/MobileAppDevelopment';
import AiAutomation from './pages/AiAutomation';
import VideoMarketing from './pages/VideoMarketing';
import ContentMarketing from './pages/ContentMarketing';
import ChatbotDevelopment from './pages/ChatbotDevelopment';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

// Admin Pages
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import Analytics from './pages/admin/Analytics';
import Seo from './pages/admin/Seo';
import Pages from './pages/admin/Pages';
import AuthGuard from './components/admin/AuthGuard';

// Blog Admin Pages
import Posts from './pages/admin/blog/Posts';
import Categories from './pages/admin/blog/Categories';
import Tags from './pages/admin/blog/Tags';

function App() {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    // Track initial page view
    trackPageView(window.location.pathname + window.location.search);
  }, [trackPageView]);

  return (
    <HelmetProvider>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/hizmetler" element={<Hizmetler />} />
        <Route 
          path="/web-tasarim" 
          element={<Navigate to="/ui-ux-tasarim" replace />} 
        />
        <Route path="/ui-ux-tasarim" element={<WebDesign />} />
        <Route path="/dijital-pazarlama" element={<DigitalMarketing />} />
        <Route 
          path="/seo-optimizasyonu" 
          element={<Navigate to="/seo-ve-icerik-optimizasyonu" replace />} 
        />
        <Route path="/seo-ve-icerik-optimizasyonu" element={<SeoOptimization />} />
        <Route path="/sosyal-medya-yonetimi" element={<SocialMediaManagement />} />
        <Route path="/marka-kimligi" element={<BrandIdentity />} />
        <Route path="/donusum-optimizasyonu" element={<ConversionOptimization />} />
        <Route path="/web-gelistirme" element={<WebDevelopment />} />
        <Route path="/e-ticaret-cozumleri" element={<EcommerceSolutions />} />
        <Route path="/mobil-uygulama-gelistirme" element={<MobileAppDevelopment />} />
        <Route path="/yapay-zeka-ve-otomasyon" element={<AiAutomation />} />
        <Route 
          path="/video-pazarlama" 
          element={<Navigate to="/video-produksiyon" replace />} 
        />
        <Route path="/video-produksiyon" element={<VideoMarketing />} />
        <Route path="/icerik-pazarlama" element={<ContentMarketing />} />
        <Route path="/chatbot-gelistirme" element={<ChatbotDevelopment />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/portfolyo" element={<Portfolio />} />
        <Route path="/ekibimiz" element={<Team />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />

        {/* Admin Routes */}
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />
        <Route path="/admin/*" element={
          <AuthGuard>
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="seo" element={<Seo />} />
              <Route path="pages" element={<Pages />} />
              <Route path="blog/posts" element={<Posts />} />
              <Route path="blog/categories" element={<Categories />} />
              <Route path="blog/tags" element={<Tags />} />
            </Routes>
          </AuthGuard>
        } />

        {/* 404 Route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;