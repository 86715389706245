import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin/AdminLayout';
import TagForm from '../../../components/admin/blog/TagForm';
import { blogService } from '../../../services/blogService';
import { 
  Plus, 
  Pencil, 
  Trash2, 
  AlertCircle,
  X,
  Tag
} from 'lucide-react';
import type { BlogTag } from '../../../types/blog';

export default function Tags() {
  const [tags, setTags] = useState<BlogTag[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTag, setSelectedTag] = useState<BlogTag | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const data = await blogService.getTags();
      setTags(data);
    } catch (err: any) {
      setError('Etiketler yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      if (selectedTag) {
        await blogService.updateTag({ id: selectedTag.id, ...formData });
      } else {
        await blogService.createTag(formData);
      }
      setIsFormOpen(false);
      setSelectedTag(null);
      fetchTags();
    } catch (err: any) {
      setError('Etiket kaydedilirken bir hata oluştu');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Bu etiketi silmek istediğinize emin misiniz?')) return;

    try {
      await blogService.deleteTag(id);
      fetchTags();
    } catch (err: any) {
      setError('Etiket silinirken bir hata oluştu');
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Blog Etiketleri</h1>
            <p className="mt-2 text-sm text-gray-600">
              Blog etiketlerini yönetin
            </p>
          </div>
          <button
            onClick={() => {
              setSelectedTag(null);
              setIsFormOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300"
          >
            <Plus className="h-5 w-5 mr-2" />
            Yeni Etiket
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {/* Tags List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {tags.map(tag => (
              <div 
                key={tag.id}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-purple-100 rounded-lg flex items-center justify-center">
                      <Tag className="h-5 w-5 text-purple-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">{tag.name}</h3>
                      <p className="text-sm text-gray-500">{tag.slug}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        setSelectedTag(tag);
                        setIsFormOpen(true);
                      }}
                      className="text-gray-400 hover:text-[#4ADE80] transition-colors"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(tag.id)}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Tag Form Modal */}
      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-md w-full">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">
                  {selectedTag ? 'Etiketi Düzenle' : 'Yeni Etiket'}
                </h3>
                <button 
                  onClick={() => {
                    setIsFormOpen(false);
                    setSelectedTag(null);
                  }}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <TagForm 
                tag={selectedTag || undefined}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}