import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import Breadcrumb from '../components/Breadcrumb';
import { usePageTitle } from '../hooks/usePageTitle';
import services from '../data/services';
import { 
  Monitor, 
  BarChart, 
  Search, 
  Megaphone, 
  Brush, 
  LineChart,
  Code,
  ShoppingBag,
  Smartphone,
  Mail,
  Target,
  Brain,
  Video,
  Bot,
  Cpu,
  MessageSquare,
  Zap,
  ArrowRight,
  Briefcase
} from 'lucide-react';

function Hizmetler() {
  usePageTitle('Hizmetlerimiz | AjansPixel - Dijital Ajans');
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  // Add Schema.org data
  useEffect(() => {
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      "name": "AjansPixel Dijital Ajans Hizmetleri",
      "description": "Web tasarım, SEO, dijital pazarlama, sosyal medya yönetimi ve daha fazlası. Markanızı dijital dünyada öne çıkaracak profesyonel çözümler.",
      "url": "https://ajanspixel.com/hizmetler",
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Ana Sayfa",
            "item": "https://ajanspixel.com"
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "Hizmetlerimiz",
            "item": "https://ajanspixel.com/hizmetler"
          }
        ]
      },
      "mainEntity": {
        "@type": "ItemList",
        "itemListElement": services.map((service, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@type": "Service",
            "name": service.title,
            "description": service.description,
            "url": `https://ajanspixel.com/${service.slug}`,
            "provider": {
              "@type": "Organization",
              "name": "AjansPixel"
            }
          }
        }))
      }
    };

    // Add schema data to the page
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schemaData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const breadcrumbItems = [
    {
      name: 'Hizmetlerimiz',
      path: '/hizmetler',
      icon: Briefcase
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      <Breadcrumb items={breadcrumbItems} />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-12">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-30" />
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-20" />
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-10" />
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">100+</span>
                    <span className="text-gray-600 font-medium">Başarılı Proje</span>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                Etkili
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">Dijital Çözümler</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">ve Hizmetler</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                Modern teknolojiler ve yaratıcı çözümlerle markanızı dijital dünyada en iyi şekilde temsil ediyoruz. Web tasarım, SEO, dijital pazarlama ve daha fazlası için profesyonel hizmetler sunuyoruz.
              </p>
              
              <div className="mt-12 flex items-center gap-6">
                <button 
                  onClick={() => setIsContactFormOpen(true)}
                  className="px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 inline-flex items-center gap-2"
                >
                  Hemen Başlayın
                  <Zap className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <div className="relative">
                <div className="absolute inset-0 bg-[#FF6B00] opacity-10 rounded-[40px]"></div>
                <img 
                  src="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=800&h=800&fit=crop" 
                  alt="Dijital Ajans Hizmetleri"
                  className="relative z-10 w-full h-[600px] object-cover object-center rounded-[40px]"
                  loading="eager"
                  fetchpriority="high"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Özellikler ve Hizmetler
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Modern ve profesyonel web sitelerinin sahip olması gereken tüm özellikler
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => {
              const Icon = service.icon;
              return (
                <div key={index} className="group relative bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]">
                  <div className={`w-16 h-16 mb-6 bg-gradient-to-br ${service.gradient} rounded-2xl flex items-center justify-center`}>
                    <Icon className="h-8 w-8 text-[#2196F3]" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  
                  {/* Service Features */}
                  <div className="space-y-2 mb-6">
                    {service.features.slice(0, 3).map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-center gap-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-[#4ADE80]" />
                        <span className="text-gray-600">{feature}</span>
                      </div>
                    ))}
                  </div>

                  {/* Detailed View Button */}
                  <Link 
                    to={`/${service.slug}`} 
                    className="service-button group-hover:bg-[#4ADE80] group-hover:text-white"
                    aria-label={`${service.title} hakkında detaylı bilgi`}
                  >
                    Detaylı İncele
                    <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />

      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </div>
  );
}

export default Hizmetler;