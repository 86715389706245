import { useCallback } from 'react';

interface ConversionEvent {
  name: string;
  category?: string;
  label?: string;
  value?: number;
  currency?: string;
  items?: any[];
  [key: string]: any;
}

export const useAnalytics = () => {
  const trackEvent = useCallback((event: ConversionEvent) => {
    // Google Analytics
    if (typeof window.gtag === 'function') {
      window.gtag('event', event.name, {
        event_category: event.category,
        event_label: event.label,
        value: event.value,
        items: event.items,
        currency: event.currency,
        ...event
      });
    }

    // Facebook Pixel
    if (typeof window.fbq === 'function') {
      window.fbq('track', event.name, {
        value: event.value,
        currency: event.currency,
        content_name: event.label,
        content_category: event.category,
        contents: event.items,
        ...event
      });
    }

    // Google Tag Manager
    if (window.dataLayer) {
      window.dataLayer.push({
        event: event.name,
        ...event
      });
    }
  }, []);

  const trackPageView = useCallback((path: string) => {
    // Google Analytics
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-8PCB81DBJ1', {
        page_path: path
      });
    }

    // Facebook Pixel
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
    }
  }, []);

  const trackConversion = useCallback((type: 'lead' | 'contact' | 'quote', data?: any) => {
    switch (type) {
      case 'lead':
        trackEvent({
          name: 'generate_lead',
          category: 'Conversion',
          label: 'Lead Form Submission',
          ...data
        });
        break;

      case 'contact':
        trackEvent({
          name: 'contact',
          category: 'Engagement',
          label: 'Contact Form Submission',
          ...data
        });
        break;

      case 'quote':
        trackEvent({
          name: 'quote_request',
          category: 'Conversion',
          label: 'Quote Request',
          ...data
        });
        break;
    }
  }, [trackEvent]);

  return {
    trackEvent,
    trackPageView,
    trackConversion
  };
};