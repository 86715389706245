import React, { useState } from 'react';
import { Loader2, Save } from 'lucide-react';
import type { BlogTag } from '../../../types/blog';

interface TagFormProps {
  tag?: BlogTag;
  onSubmit: (formData: any) => Promise<void>;
}

export default function TagForm({ tag, onSubmit }: TagFormProps) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: tag?.name || '',
    slug: tag?.slug || ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit(formData);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Etiket Adı
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Slug
        </label>
        <input
          type="text"
          value={formData.slug}
          onChange={e => setFormData(prev => ({ ...prev, slug: e.target.value }))}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
          required
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={loading}
          className="inline-flex items-center px-6 py-3 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <Loader2 className="animate-spin h-5 w-5 mr-2" />
              Kaydediliyor...
            </>
          ) : (
            <>
              <Save className="h-5 w-5 mr-2" />
              Kaydet
            </>
          )}
        </button>
      </div>
    </form>
  );
}