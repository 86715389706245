import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import ImageSEO from '../components/ImageSEO';
import SEOAnalyzer from '../components/SEOAnalyzer';
import { blogService } from '../services/blogService';
import type { BlogPost, BlogCategory, BlogTag } from '../types/blog';
import { 
  Clock, 
  User, 
  Tag,
  Folder,
  ArrowRight,
  Search,
  FileText
} from 'lucide-react';

export default function Blog() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [tags, setTags] = useState<BlogTag[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const pageContent = `
    Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri.
    Web tasarım, SEO, dijital pazarlama ve sosyal medya yönetimi konularında
    profesyonel içerikler ve güncel blog yazıları.
  `;

  const breadcrumbItems = [
    {
      name: 'Blog',
      path: '/blog',
      icon: FileText
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [postsData, categoriesData, tagsData] = await Promise.all([
          blogService.getPublishedPosts(),
          blogService.getCategories(),
          blogService.getTags()
        ]);
        setPosts(postsData);
        setCategories(categoriesData);
        setTags(tagsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredPosts = posts.filter(post => {
    const matchesCategory = !selectedCategory || post.categories?.some(c => c.id === selectedCategory);
    const matchesTag = !selectedTag || post.tags?.some(t => t.id === selectedTag);
    const matchesSearch = !searchQuery || 
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.excerpt?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesTag && matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>Blog | AjansPixel - Dijital Dünyadan En Güncel İçerikler</title>
        <meta name="description" content="Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri. Web tasarım, SEO, dijital pazarlama ve sosyal medya yönetimi konularında profesyonel içerikler." />
        <meta name="keywords" content="dijital pazarlama blog, seo blog, web tasarım blog, dijital ajans blog, sosyal medya blog" />
        <link rel="canonical" href="https://ajanspixel.com/blog" />
        
        {/* Open Graph */}
        <meta property="og:title" content="Blog | AjansPixel - Dijital Dünyadan En Güncel İçerikler" />
        <meta property="og:description" content="Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri." />
        <meta property="og:image" content="https://ajanspixel.com/images/blog.jpg" />
        <meta property="og:url" content="https://ajanspixel.com/blog" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog | AjansPixel - Dijital Dünyadan En Güncel İçerikler" />
        <meta name="twitter:description" content="Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri." />
        <meta name="twitter:image" content="https://ajanspixel.com/images/blog.jpg" />
      </Helmet>

      <Navbar />
      <Breadcrumb items={breadcrumbItems} />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-[64px] font-black leading-tight">
              Blog
              <div className="relative inline-block mx-3">
                <span className="text-[#FF6B00]">Yazıları</span>
                <div className="absolute left-0 -bottom-2 w-full">
                  <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </div>
              </div>
            </h1>
            <p className="mt-6 text-xl text-gray-600 max-w-2xl mx-auto">
              Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-12">
            {/* Sidebar */}
            <div className="lg:col-span-1 space-y-8">
              {/* Search */}
              <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                <div className="relative">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Blog yazısı ara..."
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                  />
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                </div>
              </div>

              {/* Categories */}
              <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Kategoriler</h3>
                <div className="space-y-2">
                  <button
                    onClick={() => setSelectedCategory(null)}
                    className={`w-full text-left px-4 py-2 rounded-lg transition-all duration-300 ${
                      !selectedCategory
                        ? 'bg-[#4ADE80] bg-opacity-10 text-[#4ADE80]'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    Tümü
                  </button>
                  {categories.map(category => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={`w-full text-left px-4 py-2 rounded-lg transition-all duration-300 ${
                        selectedCategory === category.id
                          ? 'bg-[#4ADE80] bg-opacity-10 text-[#4ADE80]'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      {category.name}
                    </button>
                  ))}
                </div>
              </div>

              {/* Tags */}
              <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Etiketler</h3>
                <div className="flex flex-wrap gap-2">
                  {tags.map(tag => (
                    <button
                      key={tag.id}
                      onClick={() => setSelectedTag(tag.id === selectedTag ? null : tag.id)}
                      className={`px-3 py-1 rounded-full text-sm font-medium transition-all duration-300 ${
                        selectedTag === tag.id
                          ? 'bg-[#4ADE80] text-white'
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      {tag.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Blog Posts */}
            <div className="lg:col-span-3">
              {loading ? (
                <div className="text-center py-12">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
                </div>
              ) : filteredPosts.length === 0 ? (
                <div className="text-center py-12">
                  <p className="text-gray-600">Gösterilecek blog yazısı bulunamadı.</p>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {filteredPosts.map(post => (
                    <article key={post.id} className="blog-card">
                      <div className="blog-image-container">
                        <img 
                          src={post.featured_image || 'https://images.unsplash.com/photo-1499750310107-5fef28a66643?w=800&h=500&fit=crop'} 
                          alt={post.title}
                          className="blog-image"
                        />
                        {post.categories?.[0] && (
                          <div className="absolute top-4 left-4">
                            <span className="blog-category">
                              {post.categories[0].name}
                            </span>
                          </div>
                        )}
                      </div>
                      
                      <div className="blog-content">
                        <div className="blog-meta">
                          <Clock className="h-4 w-4 mr-2" />
                          <span>{new Date(post.created_at).toLocaleDateString('tr-TR')}</span>
                        </div>
                        
                        <h3 className="blog-title">
                          <Link to={`/blog/${post.slug}`}>
                            {post.title}
                          </Link>
                        </h3>
                        
                        <p className="blog-excerpt">
                          {post.excerpt}
                        </p>
                        
                        <Link to={`/blog/${post.slug}`} className="blog-link">
                          Devamını Oku
                          <ArrowRight className="ml-2 h-4 w-4" />
                        </Link>
                      </div>
                    </article>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {process.env.NODE_ENV === 'development' && (
        <SEOAnalyzer 
          content={pageContent}
          title="Blog | AjansPixel"
          description="Dijital dünyadan en güncel içerikler, uzman görüşleri ve sektör haberleri."
          keywords={['dijital blog', 'seo blog', 'web tasarım blog']}
          url="/blog"
        />
      )}
    </>
  );
}