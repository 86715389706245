import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { supabase } from '../../lib/supabase';
import { 
  Globe, 
  Search,
  AlertCircle,
  Save,
  Loader2,
  FileText,
  Link as LinkIcon,
  Image,
  Code,
  BarChart,
  X,
  Eye,
  ArrowUpRight,
  ArrowDownRight,
  CheckCircle
} from 'lucide-react';

function Seo() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [pages, setPages] = useState<any[]>([]);
  const [selectedPage, setSelectedPage] = useState<any | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [seoAnalysis, setSeoAnalysis] = useState<{
    score: number;
    issues: string[];
    warnings: string[];
    success: string[];
  }>({
    score: 0,
    issues: [],
    warnings: [],
    success: []
  });

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const { data, error } = await supabase
        .from('pages')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPages(data || []);
    } catch (err: any) {
      setError('Sayfalar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const analyzeSEO = (page: any) => {
    const issues: string[] = [];
    const warnings: string[] = [];
    const success: string[] = [];
    let score = 100;

    // Title analizi
    if (!page.meta_title) {
      issues.push('Meta title eksik');
      score -= 10;
    } else if (page.meta_title.length < 30 || page.meta_title.length > 60) {
      warnings.push(`Meta title uzunluğu ${page.meta_title.length} karakter (30-60 karakter olmalı)`);
      score -= 5;
    } else {
      success.push('Meta title uzunluğu ideal');
    }

    // Description analizi
    if (!page.meta_description) {
      issues.push('Meta description eksik');
      score -= 10;
    } else if (page.meta_description.length < 120 || page.meta_description.length > 160) {
      warnings.push(`Meta description uzunluğu ${page.meta_description.length} karakter (120-160 karakter olmalı)`);
      score -= 5;
    } else {
      success.push('Meta description uzunluğu ideal');
    }

    // Keywords analizi
    if (!page.meta_keywords) {
      warnings.push('Meta keywords eksik');
      score -= 5;
    } else {
      success.push('Meta keywords mevcut');
    }

    // Canonical URL analizi
    if (!page.canonical_url) {
      warnings.push('Canonical URL eksik');
      score -= 5;
    } else {
      success.push('Canonical URL mevcut');
    }

    // Open Graph analizi
    if (!page.og_title || !page.og_description || !page.og_image) {
      warnings.push('Open Graph meta etiketleri eksik');
      score -= 5;
    } else {
      success.push('Open Graph meta etiketleri mevcut');
    }

    // Schema.org analizi
    if (!page.schema_data) {
      issues.push('Schema.org verisi eksik');
      score -= 10;
    } else {
      success.push('Schema.org verisi mevcut');
    }

    // Heading yapısı analizi
    if (!page.heading_structure || Object.keys(page.heading_structure).length === 0) {
      warnings.push('Heading yapısı eksik');
      score -= 5;
    } else {
      success.push('Heading yapısı mevcut');
    }

    // İç bağlantı analizi
    if (!page.internal_links || page.internal_links.length === 0) {
      warnings.push('İç bağlantılar eksik');
      score -= 5;
    } else {
      success.push('İç bağlantılar mevcut');
    }

    return {
      score,
      issues,
      warnings,
      success
    };
  };

  const handlePageSelect = (page: any) => {
    setSelectedPage(page);
    setSeoAnalysis(analyzeSEO(page));
    setIsFormOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      if (!selectedPage) return;

      const { error } = await supabase
        .from('pages')
        .update({
          meta_title: selectedPage.meta_title,
          meta_description: selectedPage.meta_description,
          meta_keywords: selectedPage.meta_keywords,
          meta_robots: selectedPage.meta_robots,
          canonical_url: selectedPage.canonical_url,
          og_title: selectedPage.og_title,
          og_description: selectedPage.og_description,
          og_image: selectedPage.og_image,
          schema_data: selectedPage.schema_data,
          heading_structure: selectedPage.heading_structure,
          internal_links: selectedPage.internal_links,
          updated_at: new Date().toISOString()
        })
        .eq('id', selectedPage.id);

      if (error) throw error;

      setSuccess('SEO ayarları başarıyla güncellendi');
      setIsFormOpen(false);
      fetchPages();
    } catch (err: any) {
      setError('SEO ayarları güncellenirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div>
          <h1 className="text-2xl font-bold text-gray-900">SEO Yönetimi</h1>
          <p className="mt-2 text-sm text-gray-600">
            Sayfa SEO ayarlarını yönetin ve analiz edin
          </p>
        </div>

        {/* Error & Success Messages */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {success && (
          <div className="flex items-center gap-2 text-green-500 bg-green-50 p-3 rounded-lg">
            <CheckCircle className="h-5 w-5" />
            <p className="text-sm">{success}</p>
          </div>
        )}

        {/* Pages List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pages.map(page => (
              <div 
                key={page.id}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                <div className="flex items-start justify-between mb-4">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{page.title}</h3>
                    <p className="text-sm text-gray-500">{page.slug}</p>
                  </div>
                  <button
                    onClick={() => handlePageSelect(page)}
                    className="p-2 text-gray-400 hover:text-[#4ADE80] transition-colors"
                  >
                    <Globe className="h-5 w-5" />
                  </button>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-sm">
                    <Search className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-600">
                      SEO Skoru: {page.seo_score || 0}/100
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <FileText className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-600">
                      Meta Başlık: {page.meta_title ? 'Var' : 'Yok'}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <LinkIcon className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-600">
                      Canonical URL: {page.canonical_url ? 'Var' : 'Yok'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* SEO Analysis Modal */}
      {isFormOpen && selectedPage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <div>
                  <h3 className="text-2xl font-bold text-gray-900">
                    SEO Analizi: {selectedPage.title}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">{selectedPage.slug}</p>
                </div>
                <button 
                  onClick={() => {
                    setIsFormOpen(false);
                    setSelectedPage(null);
                  }}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              {/* SEO Score */}
              <div className="bg-white rounded-xl p-6 shadow-lg mb-8">
                <div className="flex items-center justify-between">
                  <h4 className="text-xl font-semibold text-gray-900">SEO Skoru</h4>
                  <div className="flex items-center gap-2">
                    <span className="text-3xl font-bold text-gray-900">{seoAnalysis.score}</span>
                    <span className="text-sm text-gray-500">/100</span>
                  </div>
                </div>

                {/* Issues */}
                {seoAnalysis.issues.length > 0 && (
                  <div className="mt-6">
                    <h5 className="text-red-500 font-medium mb-2 flex items-center gap-2">
                      <ArrowDownRight className="h-5 w-5" />
                      Sorunlar
                    </h5>
                    <ul className="space-y-2">
                      {seoAnalysis.issues.map((issue, index) => (
                        <li key={index} className="flex items-center gap-2 text-red-600">
                          <AlertCircle className="h-4 w-4 flex-shrink-0" />
                          <span>{issue}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Warnings */}
                {seoAnalysis.warnings.length > 0 && (
                  <div className="mt-6">
                    <h5 className="text-yellow-500 font-medium mb-2 flex items-center gap-2">
                      <AlertCircle className="h-5 w-5" />
                      Uyarılar
                    </h5>
                    <ul className="space-y-2">
                      {seoAnalysis.warnings.map((warning, index) => (
                        <li key={index} className="flex items-center gap-2 text-yellow-600">
                          <AlertCircle className="h-4 w-4 flex-shrink-0" />
                          <span>{warning}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Success */}
                {seoAnalysis.success.length > 0 && (
                  <div className="mt-6">
                    <h5 className="text-green-500 font-medium mb-2 flex items-center gap-2">
                      <ArrowUpRight className="h-5 w-5" />
                      Başarılı
                    </h5>
                    <ul className="space-y-2">
                      {seoAnalysis.success.map((item, index) => (
                        <li key={index} className="flex items-center gap-2 text-green-600">
                          <CheckCircle className="h-4 w-4 flex-shrink-0" />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* SEO Form */}
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Meta Tags */}
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-2 mb-4">
                    <Globe className="h-5 w-5 text-gray-400" />
                    <h4 className="font-medium text-gray-900">Meta Etiketleri</h4>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Meta Başlık
                      </label>
                      <input
                        type="text"
                        value={selectedPage.meta_title || ''}
                        onChange={e => setSelectedPage(prev => ({ ...prev, meta_title: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      />
                      <p className="mt-1 text-sm text-gray-500">
                        {selectedPage.meta_title?.length || 0}/60 karakter
                      </p>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Meta Robots
                      </label>
                      <select
                        value={selectedPage.meta_robots || 'index,follow'}
                        onChange={e => setSelectedPage(prev => ({ ...prev, meta_robots: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      >
                        <option value="index,follow">index,follow</option>
                        <option value="noindex,follow">noindex,follow</option>
                        <option value="index,nofollow">index,nofollow</option>
                        <option value="noindex,nofollow">noindex,nofollow</option>
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Canonical URL
                      </label>
                      <input
                        type="url"
                        value={selectedPage.canonical_url || ''}
                        onChange={e => setSelectedPage(prev => ({ ...prev, canonical_url: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Meta Anahtar Kelimeler
                      </label>
                      <input
                        type="text"
                        value={selectedPage.meta_keywords || ''}
                        onChange={e => setSelectedPage(prev => ({ ...prev, meta_keywords: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Meta Açıklama
                    </label>
                    <textarea
                      value={selectedPage.meta_description || ''}
                      onChange={e => setSelectedPage(prev => ({ ...prev, meta_description: e.target.value }))}
                      rows={3}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      {selectedPage.meta_description?.length || 0}/160 karakter
                    </p>
                  </div>
                </div>

                {/* Open Graph Tags */}
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-2 mb-4">
                    <Image className="h-5 w-5 text-gray-400" />
                    <h4 className="font-medium text-gray-900">Open Graph Etiketleri</h4>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        OG Başlık
                      </label>
                      <input
                        type="text"
                        value={selectedPage.og_title || ''}
                        onChange={e => setSelectedPage(prev => ({ ...prev, og_title: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        OG Görsel URL
                      </label>
                      <input
                        type="url"
                        value={selectedPage.og_image || ''}
                        onChange={e => setSelectedPage(prev => ({ ...prev, og_image: e.target.value }))}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      OG Açıklama
                    </label>
                    <textarea
                      value={selectedPage.og_description || ''}
                      onChange={e => setSelectedPage(prev => ({ ...prev, og_description: e.target.value }))}
                      rows={3}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                    />
                  </div>
                </div>

                {/* Schema.org Data */}
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center gap-2 mb-4">
                    <Code className="h-5 w-5 text-gray-400" />
                    <h4 className="font-medium text-gray-900">Schema.org Data</h4>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      JSON-LD
                    </label>
                    <textarea
                      value={selectedPage.schema_data ? JSON.stringify(selectedPage.schema_data, null, 2) : ''}
                      onChange={e => {
                        try {
                          const parsed = JSON.parse(e.target.value);
                          setSelectedPage(prev => ({ ...prev, schema_data: parsed }));
                        } catch (err) {
                          // Invalid JSON, keep the text but don't update the schema_data
                        }
                      }}
                      rows={8}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    disabled={saving}
                    className="inline-flex items-center px-6 py-3 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {saving ? (
                      <>
                        <Loader2 className="animate-spin h-5 w-5 mr-2" />
                        Kaydediliyor...
                      </>
                    ) : (
                      <>
                        <Save className="h-5 w-5 mr-2" />
                        Kaydet
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default Seo;