import { supabase } from '../lib/supabase';
import type { Page, CreatePageInput, UpdatePageInput } from '../types/page';

export const pageService = {
  async getPages() {
    try {
      const { data, error } = await supabase
        .from('pages')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching pages:', error);
        throw error;
      }

      // Ensure proper JSON structure for heading_structure and internal_links
      return (data || []).map(page => ({
        ...page,
        heading_structure: typeof page.heading_structure === 'object' ? page.heading_structure : {},
        internal_links: Array.isArray(page.internal_links) ? page.internal_links : []
      })) as Page[];
    } catch (error) {
      console.error('Error in getPages:', error);
      throw error;
    }
  },

  async getPageBySlug(slug: string) {
    try {
      const { data, error } = await supabase
        .from('pages')
        .select('*')
        .eq('slug', slug)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        return {
          ...data,
          heading_structure: typeof data.heading_structure === 'object' ? data.heading_structure : {},
          internal_links: Array.isArray(data.internal_links) ? data.internal_links : []
        } as Page;
      }

      return null;
    } catch (error) {
      console.error('Error getting page by slug:', error);
      throw error;
    }
  },

  async createPage(input: CreatePageInput) {
    try {
      const existingPage = await this.getPageBySlug(input.slug);
      if (existingPage) {
        throw new Error(`"${input.slug}" slug'ına sahip bir sayfa zaten mevcut`);
      }

      const { data, error } = await supabase
        .from('pages')
        .insert({
          ...input,
          content: input.content || {},
          heading_structure: typeof input.heading_structure === 'object' ? input.heading_structure : {},
          internal_links: Array.isArray(input.internal_links) ? input.internal_links : [],
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        })
        .select()
        .single();

      if (error) throw error;
      return data as Page;
    } catch (error) {
      console.error('Error creating page:', error);
      throw error;
    }
  },

  async updatePage(input: UpdatePageInput) {
    const { id, ...updateData } = input;
    
    try {
      if (updateData.slug) {
        const existingPage = await this.getPageBySlug(updateData.slug);
        if (existingPage && existingPage.id !== id) {
          throw new Error(`"${updateData.slug}" slug'ına sahip başka bir sayfa zaten mevcut`);
        }
      }

      const { data, error } = await supabase
        .from('pages')
        .update({
          ...updateData,
          heading_structure: typeof updateData.heading_structure === 'object' ? updateData.heading_structure : {},
          internal_links: Array.isArray(updateData.internal_links) ? updateData.internal_links : [],
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data as Page;
    } catch (error) {
      console.error('Error updating page:', error);
      throw error;
    }
  },

  async deletePage(id: string) {
    try {
      const { error } = await supabase
        .from('pages')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting page:', error);
      throw error;
    }
  }
};