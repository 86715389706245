import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AdminLayout from '../../components/admin/AdminLayout';
import { pageService } from '../../services/pageService';
import { 
  Plus, 
  Pencil, 
  Trash2, 
  AlertCircle,
  X,
  FileText,
  Clock,
  LayoutIcon,
  Eye,
  Globe,
  Image,
  Code
} from 'lucide-react';
import type { Page } from '../../types/page';

function Pages() {
  const [pages, setPages] = useState<Page[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    content: {},
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    meta_robots: 'index,follow',
    canonical_url: '',
    og_title: '',
    og_description: '',
    og_image: '',
    heading_structure: {} as Record<string, string>,
    internal_links: [] as string[],
    schema_data: null as Record<string, any> | null,
    layout: 'default',
    published: false,
    published_at: null as string | null,
    image_alt_text: '',
    image_title: '',
    image_caption: '',
    image_filename: '',
    image_dimensions: '',
    image_filesize: '',
    image_format: '',
    image_lazy_loading: true
  });

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const data = await pageService.getPages();
      // Filter out any potential duplicate home page entries
      const uniquePages = data.filter((page, index, self) => 
        page.slug === '/' ? 
          index === self.findIndex(p => p.slug === '/') : 
          true
      );
      setPages(uniquePages);
    } catch (err: any) {
      setError('Sayfalar yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedPage) {
        await pageService.updatePage({ id: selectedPage.id, ...formData });
      } else {
        await pageService.createPage(formData);
      }
      setIsFormOpen(false);
      setSelectedPage(null);
      setFormData({
        title: '',
        slug: '',
        content: {},
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        meta_robots: 'index,follow',
        canonical_url: '',
        og_title: '',
        og_description: '',
        og_image: '',
        heading_structure: {},
        internal_links: [],
        schema_data: null,
        layout: 'default',
        published: false,
        published_at: null,
        image_alt_text: '',
        image_title: '',
        image_caption: '',
        image_filename: '',
        image_dimensions: '',
        image_filesize: '',
        image_format: '',
        image_lazy_loading: true
      });
      fetchPages();
    } catch (err: any) {
      setError('Sayfa kaydedilirken bir hata oluştu');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Bu sayfayı silmek istediğinize emin misiniz?')) return;

    try {
      await pageService.deletePage(id);
      fetchPages();
    } catch (err: any) {
      setError('Sayfa silinirken bir hata oluştu');
    }
  };

  const handleEdit = (page: Page) => {
    setSelectedPage(page);
    setFormData({
      title: page.title,
      slug: page.slug,
      content: page.content || {},
      meta_title: page.meta_title || '',
      meta_description: page.meta_description || '',
      meta_keywords: page.meta_keywords || '',
      meta_robots: page.meta_robots || 'index,follow',
      canonical_url: page.canonical_url || '',
      og_title: page.og_title || '',
      og_description: page.og_description || '',
      og_image: page.og_image || '',
      heading_structure: typeof page.heading_structure === 'object' ? page.heading_structure : {},
      internal_links: Array.isArray(page.internal_links) ? page.internal_links : [],
      schema_data: page.schema_data,
      layout: page.layout,
      published: page.published,
      published_at: page.published_at,
      image_alt_text: page.image_alt_text || '',
      image_title: page.image_title || '',
      image_caption: page.image_caption || '',
      image_filename: page.image_filename || '',
      image_dimensions: page.image_dimensions || '',
      image_filesize: page.image_filesize || '',
      image_format: page.image_format || '',
      image_lazy_loading: page.image_lazy_loading !== false
    });
    setIsFormOpen(true);
  };

  const autoFillSeoFields = () => {
    let canonicalSlug = formData.slug;
    if (!canonicalSlug.startsWith('/')) {
      canonicalSlug = `/${canonicalSlug}`;
    }
    if (canonicalSlug === '/') {
      canonicalSlug = '';
    }

    // Generate schema.org data
    const schemaData = {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      name: formData.title,
      description: formData.meta_description || `${formData.title} - AjansPixel`,
      url: `https://ajanspixel.com${canonicalSlug}`,
      breadcrumb: {
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Ana Sayfa',
            item: 'https://ajanspixel.com'
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: formData.title,
            item: `https://ajanspixel.com${canonicalSlug}`
          }
        ]
      }
    };

    setFormData(prev => ({
      ...prev,
      meta_title: prev.title,
      meta_description: `${prev.title} - AjansPixel. ${prev.title} sayfamızı ziyaret edin ve detaylı bilgi alın.`,
      meta_keywords: `${prev.title.toLowerCase()}, ajanspixel, dijital ajans`,
      meta_robots: 'index,follow',
      canonical_url: `https://ajanspixel.com${canonicalSlug}`,
      og_title: prev.title,
      og_description: `${prev.title} - AjansPixel. ${prev.title} sayfamızı ziyaret edin ve detaylı bilgi alın.`,
      og_image: 'https://ajanspixel.com/og-image.jpg',
      schema_data: schemaData
    }));
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = e.target.value;
    let canonicalSlug = newSlug;
    
    if (!canonicalSlug.startsWith('/')) {
      canonicalSlug = `/${canonicalSlug}`;
    }
    if (canonicalSlug === '/') {
      canonicalSlug = '';
    }

    setFormData(prev => ({ 
      ...prev, 
      slug: newSlug,
      canonical_url: `https://ajanspixel.com${canonicalSlug}`
    }));
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Sayfalar</h1>
            <p className="mt-2 text-sm text-gray-600">
              Web sitesi sayfalarını yönetin
            </p>
          </div>
          <button
            onClick={() => {
              setSelectedPage(null);
              setFormData({
                title: '',
                slug: '',
                content: {},
                meta_title: '',
                meta_description: '',
                meta_keywords: '',
                meta_robots: 'index,follow',
                canonical_url: '',
                og_title: '',
                og_description: '',
                og_image: '',
                heading_structure: {},
                internal_links: [],
                schema_data: null,
                layout: 'default',
                published: false,
                published_at: null,
                image_alt_text: '',
                image_title: '',
                image_caption: '',
                image_filename: '',
                image_dimensions: '',
                image_filesize: '',
                image_format: '',
                image_lazy_loading: true
              });
              setIsFormOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300"
          >
            <Plus className="h-5 w-5 mr-2" />
            Yeni Sayfa
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {/* Pages List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {pages.map(page => (
              <div 
                key={page.id}
                className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
                      <FileText className="h-5 w-5 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900">{page.title}</h3>
                      <p className="text-sm text-gray-500">{page.slug}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleEdit(page)}
                      className="text-gray-400 hover:text-[#4ADE80] transition-colors"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(page.id)}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="mt-4 flex items-center gap-4 text-sm text-gray-500">
                  <div className="flex items-center gap-1">
                    <Clock className="h-4 w-4" />
                    {new Date(page.updated_at).toLocaleDateString('tr-TR')}
                  </div>
                  <div className="flex items-center gap-1">
                    <LayoutIcon className="h-4 w-4" />
                    {page.layout}
                  </div>
                  <div className={`px-2 py-1 rounded-full text-xs font-medium ${
                    page.published 
                      ? 'bg-green-100 text-green-600' 
                      : 'bg-yellow-100 text-yellow-600'
                  }`}>
                    {page.published ? 'Yayında' : 'Taslak'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Page Form Modal */}
      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">
                  {selectedPage ? 'Sayfayı Düzenle' : 'Yeni Sayfa'}
                </h3>
                <button 
                  onClick={() => {
                    setIsFormOpen(false);
                    setSelectedPage(null);
                    setFormData({
                      title: '',
                      slug: '',
                      content: {},
                      meta_title: '',
                      meta_description: '',
                      meta_keywords: '',
                      meta_robots: 'index,follow',
                      canonical_url: '',
                      og_title: '',
                      og_description: '',
                      og_image: '',
                      heading_structure: {},
                      internal_links: [],
                      schema_data: null,
                      layout: 'default',
                      published: false,
                      published_at: null,
                      image_alt_text: '',
                      image_title: '',
                      image_caption: '',
                      image_filename: '',
                      image_dimensions: '',
                      image_filesize: '',
                      image_format: '',
                      image_lazy_loading: true
                    });
                  }}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sayfa Başlığı
                  </label>
                  <input
                    type="text"
                    value={formData.title}
                    onChange={e => setFormData(prev => ({ ...prev, title: e.target.value }))}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Slug
                  </label>
                  <input
                    type="text"
                    value={formData.slug}
                    onChange={handleSlugChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                    required
                  />
                </div>

                {/* SEO Settings */}
                <div className="space-y-6">
                  {/* SEO Auto Fill Button */}
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={autoFillSeoFields}
                      className="inline-flex items-center px-4 py-2 text-[#4ADE80] font-medium rounded-lg hover:bg-[#4ADE80] hover:bg-opacity-10 transition-all duration-300"
                    >
                      <Eye className="h-5 w-5 mr-2" />
                      SEO Alanlarını Otomatik Doldur
                    </button>
                  </div>

                  {/* Meta Tags */}
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                      <Globe className="h-5 w-5 text-gray-400" />
                      <h4 className="font-medium text-gray-900">Meta Etiketleri</h4>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Meta Başlık
                        </label>
                        <input
                          type="text"
                          value={formData.meta_title}
                          onChange={e => setFormData(prev => ({ ...prev, meta_title: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                        />
                        <p className="mt-1 text-sm text-gray-500">
                          {formData.meta_title.length}/60 karakter
                        </p>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Meta Robots
                        </label>
                        <select
                          value={formData.meta_robots}
                          onChange={e => setFormData(prev => ({ ...prev, meta_robots: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                        >
                          <option value="">Varsayılan</option>
                          <option value="index,follow">index,follow</option>
                          <option value="noindex,follow">noindex,follow</option>
                          <option value="index,nofollow">index,nofollow</option>
                          <option value="noindex,nofollow">noindex,nofollow</option>
                        </select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Canonical URL
                        </label>
                        <input
                          type="url"
                          value={formData.canonical_url}
                          onChange={e => setFormData(prev => ({ ...prev, canonical_url: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                          placeholder="https://example.com/page"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Meta Anahtar Kelimeler
                        </label>
                        <input
                          type="text"
                          value={formData.meta_keywords}
                          onChange={e => setFormData(prev => ({ ...prev, meta_keywords: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                          placeholder="anahtar,kelime,örnek"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Meta Açıklama
                      </label>
                      <textarea
                        value={formData.meta_description}
                        onChange={e => setFormData(prev => ({ ...prev, meta_description: e.target.value }))}
                        rows={3}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent resize-none"
                      />
                      <p className="mt-1 text-sm text-gray-500">
                        {formData.meta_description.length}/160 karakter
                      </p>
                    </div>
                  </div>

                  {/* Open Graph Tags */}
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                      <Image className="h-5 w-5 text-gray-400" />
                      <h4 className="font-medium text-gray-900">Open Graph Etiketleri</h4>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          OG Başlık
                        </label>
                        <input
                          type="text"
                          value={formData.og_title}
                          onChange={e => setFormData(prev => ({ ...prev, og_title: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          OG Görsel URL
                        </label>
                        <input
                          type="url"
                          value={formData.og_image}
                          onChange={e => setFormData(prev => ({ ...prev, og_image: e.target.value }))}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                          placeholder="https://example.com/image.jpg"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        OG Açıklama
                      </label>
                      <textarea
                        value={formData.og_description}
                        onChange={e => setFormData(prev => ({ ...prev, og_description: e.target.value }))}
                        rows={3}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent resize-none"
                      />
                    </div>
                  </div>

                  {/* Schema.org Data */}
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                      <Code className="h-5 w-5 text-gray-400" />
                      <h4 className="font-medium text-gray-900">Schema.org Data</h4>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        JSON-LD
                      </label>
                      <textarea
                        value={formData.schema_data ? JSON.stringify(formData.schema_data, null, 2) : ''}
                        onChange={e => {
                          try {
                            const parsed = JSON.parse(e.target.value);
                            setFormData(prev => ({ ...prev, schema_data: parsed }));
                          } catch (err) {
                            // Invalid JSON, keep the text but don't update the schema_data
                          }
                        }}
                        rows={8}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                        placeholder="Enter JSON-LD schema data"
                      />
                    </div>
                  </div>

                  {/* Content Optimization */}
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-center gap-2 mb-4">
                      <FileText className="h-5 w-5 text-gray-400" />
                      <h4 className="font-medium text-gray-900">İçerik Optimizasyonu</h4>
                    </div>
                    
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Başlık Yapısı
                        </label>
                        <div className="space-y-2">
                          {Object.entries(formData.heading_structure).map(([level, text], index) => (
                            <div key={index} className="flex items-center gap-2">
                              <select
                                value={level}
                                onChange={e => {
                                  const newStructure = { ...formData.heading_structure };
                                  delete newStructure[level];
                                  newStructure[e.target.value] = text;
                                  setFormData(prev => ({ ...prev, heading_structure: newStructure }));
                                }}
                                className="w-24 px-2 py-1 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                              >
                                <option value="h1">H1</option>
                                <option value="h2">H2</option>
                                <option value="h3">H3</option>
                                <option value="h4">H4</option>
                                <option value="h5">H5</option>
                                <option value="h6">H6</option>
                              </select>
                              <input
                                type="text"
                                value={text as string}
                                onChange={e => {
                                  const newStructure = { ...formData.heading_structure };
                                  newStructure[level] = e.target.value;
                                  setFormData(prev => ({ ...prev, heading_structure: newStructure }));
                                }}
                                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                                placeholder="Başlık metni"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  const newStructure = { ...formData.heading_structure };
                                  delete newStructure[level];
                                  setFormData(prev => ({ ...prev, heading_structure: newStructure }));
                                }}
                                className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                              >
                                <X className="h-5 w-5" />
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              const newStructure = { ...formData.heading_structure };
                              const level = `h${Object.keys(newStructure).length + 1}`;
                              newStructure[level] = '';
                              setFormData(prev => ({ ...prev, heading_structure: newStructure }));
                            }}
                            className="px-4 py-2 text-[#4ADE80] font-medium hover:bg-[#4ADE80] hover:bg-opacity-10 rounded-lg transition-colors"
                          >
                            + Başlık Ekle
                          </button>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          İç Bağlantılar
                        </label>
                        <div className="space-y-2">
                          {formData.internal_links.map((link, index) => (
                            <div key={index} className="flex items-center gap-2">
                              <input
                                type="text"
                                value={link}
                                onChange={e => {
                                  const newLinks = [...formData.internal_links];
                                  newLinks[index] = e.target.value;
                                  setFormData(prev => ({ ...prev, internal_links: newLinks }));
                                }}
                                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                                placeholder="/ornek-sayfa"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  const newLinks = [...formData.internal_links];
                                  newLinks.splice(index, 1);
                                  setFormData(prev => ({ ...prev, internal_links: newLinks }));
                                }}
                                className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                              >
                                <X className="h-5 w-5" />
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                internal_links: [...prev.internal_links, '']
                              }));
                            }}
                            className="px-4 py-2 text-[#4ADE80] font-medium hover:bg-[#4ADE80] hover:bg-opacity-10 rounded-lg transition-colors"
                          >
                            + Bağlantı Ekle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="inline-flex items-center px-6 py-3 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300"
                  >
                    {selectedPage ? 'Güncelle' : 'Oluştur'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default Pages;