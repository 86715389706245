import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin/AdminLayout';
import PostForm from '../../../components/admin/blog/PostForm';
import { blogService } from '../../../services/blogService';
import { 
  Plus, 
  Pencil, 
  Trash2, 
  AlertCircle,
  X,
  FileText,
  Clock,
  Tag,
  Folder
} from 'lucide-react';
import type { BlogPost } from '../../../types/blog';

export default function Posts() {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const data = await blogService.getPosts();
      setPosts(data);
    } catch (err: any) {
      setError('Blog yazıları yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      if (selectedPost) {
        await blogService.updatePost({ id: selectedPost.id, ...formData });
      } else {
        await blogService.createPost(formData);
      }
      setIsFormOpen(false);
      setSelectedPost(null);
      fetchPosts();
    } catch (err: any) {
      setError('Blog yazısı kaydedilirken bir hata oluştu');
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm('Bu blog yazısını silmek istediğinize emin misiniz?')) return;

    try {
      await blogService.deletePost(id);
      fetchPosts();
    } catch (err: any) {
      setError('Blog yazısı silinirken bir hata oluştu');
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Blog Yazıları</h1>
            <p className="mt-2 text-sm text-gray-600">
              Blog yazılarını yönetin
            </p>
          </div>
          <button
            onClick={() => {
              setSelectedPost(null);
              setIsFormOpen(true);
            }}
            className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300"
          >
            <Plus className="h-5 w-5 mr-2" />
            Yeni Yazı
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {/* Posts List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#4ADE80] mx-auto"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map(post => (
              <div 
                key={post.id}
                className="bg-white rounded-2xl overflow-hidden shadow-sm border border-gray-100 hover:border-[#4ADE80] transition-all duration-300"
              >
                {post.featured_image && (
                  <div className="relative h-48">
                    <img 
                      src={post.featured_image}
                      alt={post.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute top-4 right-4 flex gap-2">
                      <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                        post.status === 'published' 
                          ? 'bg-green-100 text-green-600' 
                          : 'bg-yellow-100 text-yellow-600'
                      }`}>
                        {post.status === 'published' ? 'Yayında' : 'Taslak'}
                      </span>
                    </div>
                  </div>
                )}
                
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{post.title}</h3>
                  
                  <div className="flex items-center gap-4 text-sm text-gray-500 mb-4">
                    <div className="flex items-center gap-1">
                      <Clock className="h-4 w-4" />
                      {new Date(post.created_at).toLocaleDateString('tr-TR')}
                    </div>
                  </div>

                  {/* Categories */}
                  {post.categories && post.categories.length > 0 && (
                    <div className="flex items-center gap-2 mb-3">
                      <Folder className="h-4 w-4 text-gray-400" />
                      <div className="flex flex-wrap gap-2">
                        {post.categories.map(category => (
                          <span key={category.id} className="text-sm text-gray-600">
                            {category.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Tags */}
                  {post.tags && post.tags.length > 0 && (
                    <div className="flex items-center gap-2 mb-4">
                      <Tag className="h-4 w-4 text-gray-400" />
                      <div className="flex flex-wrap gap-2">
                        {post.tags.map(tag => (
                          <span key={tag.id} className="text-sm text-gray-600">
                            {tag.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

                  {post.excerpt && (
                    <p className="text-gray-600 line-clamp-3 mb-6">{post.excerpt}</p>
                  )}

                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => {
                        setSelectedPost(post);
                        setIsFormOpen(true);
                      }}
                      className="text-gray-400 hover:text-[#4ADE80] transition-colors"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(post.id)}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Post Form Modal */}
      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">
                  {selectedPost ? 'Blog Yazısını Düzenle' : 'Yeni Blog Yazısı'}
                </h3>
                <button 
                  onClick={() => {
                    setIsFormOpen(false);
                    setSelectedPost(null);
                  }}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <PostForm 
                post={selectedPost || undefined}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
}