import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import { usePageTitle } from '../hooks/usePageTitle';
import { 
  Monitor, 
  ShoppingBag, 
  Smartphone, 
  Globe, 
  Brush, 
  Video,
  Zap,
  ArrowRight,
  ExternalLink,
  Eye,
  Award,
  Star,
  X
} from 'lucide-react';

// Proje kategorileri
const categories = [
  { id: 'all', name: 'Tümü' },
  { id: 'web', name: 'Web Tasarım', icon: Monitor },
  { id: 'ecommerce', name: 'E-ticaret', icon: ShoppingBag },
  { id: 'mobile', name: 'Mobil Uygulama', icon: Smartphone },
  { id: 'brand', name: 'Marka Kimliği', icon: Brush },
  { id: 'video', name: 'Video Prodüksiyon', icon: Video }
];

// Örnek projeler
const projects = [
  {
    id: 1,
    title: 'E-ticaret Platformu',
    category: 'ecommerce',
    image: 'https://images.unsplash.com/photo-1556742031-c6961e8560b0?w=800&h=500&fit=crop',
    client: 'Fashion Store',
    description: 'Modern ve kullanıcı dostu e-ticaret platformu',
    features: ['Özel Tasarım', 'Mobil Uyumlu', 'SEO Optimizasyonu'],
    results: ['6 Ayda %150 Satış Artışı', '%40 Dönüşüm Oranı']
  },
  {
    id: 2,
    title: 'Kurumsal Web Sitesi',
    category: 'web',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=500&fit=crop',
    client: 'Tech Solutions',
    description: 'Profesyonel ve etkileyici kurumsal kimlik',
    features: ['Modern Tasarım', 'Blog Sistemi', 'Çok Dilli Yapı'],
    results: ['%85 Bounce Rate Düşüşü', '%200 Organik Trafik Artışı']
  },
  {
    id: 3,
    title: 'Mobil Uygulama',
    category: 'mobile',
    image: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?w=800&h=500&fit=crop',
    client: 'Fitness App',
    description: 'iOS ve Android fitness uygulaması',
    features: ['Özel UI/UX', 'Performans Takibi', 'Sosyal Özellikler'],
    results: ['100K+ İndirme', '4.8 Yıldız Değerlendirme']
  },
  {
    id: 4,
    title: 'Marka Kimliği',
    category: 'brand',
    image: 'https://images.unsplash.com/photo-1434626881859-194d67b2b86f?w=800&h=500&fit=crop',
    client: 'Organic Foods',
    description: 'Sürdürülebilir gıda markası kimliği',
    features: ['Logo Tasarımı', 'Ambalaj Tasarımı', 'Marka Kılavuzu'],
    results: ['%95 Müşteri Memnuniyeti', '%120 Marka Bilinirliği Artışı']
  },
  {
    id: 5,
    title: 'Video Prodüksiyon',
    category: 'video',
    image: 'https://images.unsplash.com/photo-1492691527719-9d1e07e534b4?w=800&h=500&fit=crop',
    client: 'Travel Agency',
    description: 'Etkileyici tanıtım filmi prodüksiyonu',
    features: ['4K Çekim', 'Drone Görüntüleri', 'Profesyonel Kurgu'],
    results: ['1M+ İzlenme', '%40 Rezervasyon Artışı']
  },
  {
    id: 6,
    title: 'E-ticaret Mobil App',
    category: 'mobile',
    image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=800&h=500&fit=crop',
    client: 'Shopping Mall',
    description: 'Alışveriş merkezi mobil uygulaması',
    features: ['Kolay Kullanım', 'Sadakat Programı', 'Push Bildirimler'],
    results: ['50K+ Aktif Kullanıcı', '%75 Tekrar Ziyaret']
  }
];

function Portfolio() {
  usePageTitle('Portfolyo');
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('all');
  const [selectedProject, setSelectedProject] = useState<number | null>(null);

  const filteredProjects = activeCategory === 'all' 
    ? projects 
    : projects.filter(project => project.category === activeCategory);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-30" />
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-20" />
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-10" />
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">100+</span>
                    <span className="text-gray-600 font-medium">Başarılı Proje</span>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                Yaratıcı
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">Portfolyo</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">Çalışmaları</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                Modern teknolojiler ve yaratıcı çözümlerle hayata geçirdiğimiz başarılı projelerimizi keşfedin.
              </p>
              
              <div className="mt-12 flex items-center gap-6">
                <button 
                  onClick={() => setIsContactFormOpen(true)}
                  className="px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 inline-flex items-center gap-2"
                >
                  Projenizi Başlatalım
                  <Zap className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <div className="relative">
                <div className="absolute inset-0 bg-[#FF6B00] opacity-10 rounded-[40px]"></div>
                <img 
                  src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=800&fit=crop" 
                  alt="Portfolyo" 
                  className="relative z-10 w-full h-[600px] object-cover object-center rounded-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Categories */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-center gap-4">
            {categories.map(category => {
              const Icon = category.icon;
              return (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`px-6 py-3 rounded-xl flex items-center gap-2 transition-all duration-300 ${
                    activeCategory === category.id
                      ? 'bg-[#4ADE80] text-white'
                      : 'bg-white text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  {Icon && <Icon className="h-5 w-5" />}
                  {category.name}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      {/* Projects Grid */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProjects.map(project => (
              <div 
                key={project.id}
                className="group relative bg-white rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]"
              >
                <div className="relative h-64">
                  <img 
                    src={project.image} 
                    alt={project.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <button
                      onClick={() => setSelectedProject(project.id)}
                      className="px-6 py-3 bg-white text-gray-900 rounded-xl flex items-center gap-2 transform -translate-y-4 group-hover:translate-y-0 transition-all duration-300"
                    >
                      Detayları İncele
                      <Eye className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">{project.client}</span>
                    <div className="flex items-center gap-2">
                      <Award className="h-5 w-5 text-[#FFD700]" />
                      <Star className="h-5 w-5 text-[#FFD700]" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-3xl p-12 relative overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute -top-12 -left-12 w-72 h-72 bg-[#FF5733] rounded-full"></div>
              <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute -bottom-12 -right-12 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
            </div>
            
            <div className="relative text-center max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-6">Projenizi Hayata Geçirelim</h2>
              <p className="text-lg text-gray-600 mb-8">
                Yaratıcı çözümlerimizle markanızı bir adım öne taşıyalım.
              </p>
              <button 
                onClick={() => setIsContactFormOpen(true)}
                className="inline-flex items-center px-8 py-4 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 group"
              >
                Hemen İletişime Geçin
                <Zap className="ml-2 h-5 w-5 transform group-hover:-rotate-12 transition-transform duration-300" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Project Detail Modal */}
      {selectedProject && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-start mb-6">
                <h3 className="text-2xl font-bold text-gray-900">
                  {projects.find(p => p.id === selectedProject)?.title}
                </h3>
                <button 
                  onClick={() => setSelectedProject(null)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              
              <img 
                src={projects.find(p => p.id === selectedProject)?.image}
                alt={projects.find(p => p.id === selectedProject)?.title}
                className="w-full h-64 object-cover rounded-xl mb-6"
              />

              <div className="space-y-6">
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-2">Proje Detayları</h4>
                  <p className="text-gray-600">
                    {projects.find(p => p.id === selectedProject)?.description}
                  </p>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-2">Özellikler</h4>
                  <ul className="space-y-2">
                    {projects.find(p => p.id === selectedProject)?.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2 text-gray-600">
                        <div className="w-1.5 h-1.5 rounded-full bg-[#4ADE80]" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h4 className="text-lg font-semibold text-gray-900 mb-2">Sonuçlar</h4>
                  <ul className="space-y-2">
                    {projects.find(p => p.id === selectedProject)?.results.map((result, index) => (
                      <li key={index} className="flex items-center gap-2 text-gray-600">
                        <Award className="h-5 w-5 text-[#FFD700]" />
                        {result}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />

      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </div>
  );
}

export default Portfolio;