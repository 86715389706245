import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Settings, 
  MessageSquare, 
  LogOut,
  Menu,
  X,
  FileText,
  Folder,
  Tag,
  File,
  BarChart
} from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface AdminLayoutProps {
  children: React.ReactNode;
}

function AdminLayout({ children }: AdminLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isBlogSubmenuOpen, setIsBlogSubmenuOpen] = useState(
    location.pathname.startsWith('/admin/blog/')
  );

  const navigation = [
    { name: 'Dashboard', href: '/admin/dashboard', icon: LayoutDashboard },
    { name: 'Analytics', href: '/admin/analytics', icon: BarChart },
    { name: 'Sayfalar', href: '/admin/pages', icon: File },
    { name: 'SEO Ayarları', href: '/admin/seo', icon: Settings },
    { name: 'Form Yanıtları', href: '/admin/forms', icon: MessageSquare },
    {
      name: 'Blog Yönetimi',
      icon: FileText,
      submenu: [
        { name: 'Yazılar', href: '/admin/blog/posts', icon: FileText },
        { name: 'Kategoriler', href: '/admin/blog/categories', icon: Folder },
        { name: 'Etiketler', href: '/admin/blog/tags', icon: Tag }
      ]
    }
  ];

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/admin/login');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile menu button */}
      <div className="lg:hidden fixed top-4 right-4 z-50">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="p-2 rounded-lg bg-white shadow-lg"
        >
          {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>
      </div>

      {/* Sidebar */}
      <div className={`
        fixed inset-y-0 left-0 z-40 w-64 bg-white border-r border-gray-200 transform transition-transform duration-300 ease-in-out
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
      `}>
        <div className="h-full flex flex-col">
          {/* Logo */}
          <div className="flex-shrink-0 px-6 py-8 border-b border-gray-200">
            <Link to="/admin/dashboard" className="flex flex-col items-center">
              <span className="text-3xl logo-text">
                <span className="text-[#F39200]">Ajans</span>
                <span className="text-[#2196F3]">Pixel</span>
              </span>
              <span className="text-sm text-gray-500 -mt-1">Admin Panel</span>
            </Link>
          </div>

          {/* Navigation */}
          <nav className="flex-1 px-4 py-6 space-y-2 overflow-y-auto">
            {navigation.map((item) => {
              const Icon = item.icon;
              const isActive = item.submenu 
                ? item.submenu.some(subitem => location.pathname === subitem.href)
                : location.pathname === item.href;

              if (item.submenu) {
                return (
                  <div key={item.name}>
                    <button
                      onClick={() => setIsBlogSubmenuOpen(!isBlogSubmenuOpen)}
                      className={`
                        w-full flex items-center justify-between px-4 py-3 rounded-lg transition-all duration-300
                        ${isActive 
                          ? 'bg-[#4ADE80] bg-opacity-10 text-[#4ADE80]' 
                          : 'text-gray-600 hover:bg-gray-50'}
                      `}
                    >
                      <div className="flex items-center">
                        <Icon className="h-5 w-5 mr-3" />
                        {item.name}
                      </div>
                      <svg
                        className={`w-4 h-4 transition-transform duration-200 ${
                          isBlogSubmenuOpen ? 'transform rotate-180' : ''
                        }`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    {isBlogSubmenuOpen && (
                      <div className="mt-2 pl-4 space-y-2">
                        {item.submenu.map((subitem) => {
                          const SubIcon = subitem.icon;
                          const isSubActive = location.pathname === subitem.href;
                          
                          return (
                            <Link
                              key={subitem.name}
                              to={subitem.href}
                              className={`
                                flex items-center px-4 py-3 rounded-lg transition-all duration-300
                                ${isSubActive 
                                  ? 'bg-[#4ADE80] bg-opacity-10 text-[#4ADE80]' 
                                  : 'text-gray-600 hover:bg-gray-50'}
                              `}
                            >
                              <SubIcon className="h-5 w-5 mr-3" />
                              {subitem.name}
                            </Link>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              }

              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`
                    flex items-center px-4 py-3 rounded-lg transition-all duration-300
                    ${isActive 
                      ? 'bg-[#4ADE80] bg-opacity-10 text-[#4ADE80]' 
                      : 'text-gray-600 hover:bg-gray-50'}
                  `}
                >
                  <Icon className="h-5 w-5 mr-3" />
                  {item.name}
                </Link>
              );
            })}
          </nav>

          {/* User Menu */}
          <div className="flex-shrink-0 border-t border-gray-200">
            <div className="px-4 py-6">
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-4 py-3 text-gray-600 hover:bg-gray-50 rounded-lg transition-all duration-300"
              >
                <LogOut className="h-5 w-5 mr-3" />
                Çıkış Yap
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="lg:pl-64">
        <main className="py-8 px-4 sm:px-6 lg:px-8">
          {children}
        </main>
      </div>
    </div>
  );
}

export default AdminLayout;