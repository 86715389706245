import { useEffect } from 'react';

export const PerformanceMetrics = () => {
  useEffect(() => {
    // Report Web Vitals
    const reportWebVitals = async () => {
      if ('performance' in window) {
        try {
          // First Contentful Paint (FCP)
          const fcpEntry = await getFCP();
          console.log('FCP:', fcpEntry);

          // Largest Contentful Paint (LCP)
          const lcpEntry = await getLCP();
          console.log('LCP:', lcpEntry);

          // First Input Delay (FID)
          const fidEntry = await getFID();
          console.log('FID:', fidEntry);

          // Cumulative Layout Shift (CLS)
          const clsEntry = await getCLS();
          console.log('CLS:', clsEntry);

          // Time to First Byte (TTFB)
          const ttfbEntry = await getTTFB();
          console.log('TTFB:', ttfbEntry);

          // First Paint (FP)
          const fpEntry = await getFP();
          console.log('FP:', fpEntry);

          // Send metrics to analytics
          sendToAnalytics({
            FCP: fcpEntry,
            LCP: lcpEntry,
            FID: fidEntry,
            CLS: clsEntry,
            TTFB: ttfbEntry,
            FP: fpEntry
          });
        } catch (error) {
          console.error('Error measuring performance:', error);
        }
      }
    };

    // Get FCP
    const getFCP = () => {
      return new Promise((resolve) => {
        new PerformanceObserver((list) => {
          const entries = list.getEntries();
          const entry = entries[entries.length - 1];
          resolve(entry.startTime);
        }).observe({ type: 'paint', buffered: true });
      });
    };

    // Get LCP
    const getLCP = () => {
      return new Promise((resolve) => {
        new PerformanceObserver((list) => {
          const entries = list.getEntries();
          const entry = entries[entries.length - 1];
          resolve(entry.startTime);
        }).observe({ type: 'largest-contentful-paint', buffered: true });
      });
    };

    // Get FID
    const getFID = () => {
      return new Promise((resolve) => {
        new PerformanceObserver((list) => {
          const entries = list.getEntries();
          const entry = entries[0];
          resolve(entry.processingStart - entry.startTime);
        }).observe({ type: 'first-input', buffered: true });
      });
    };

    // Get CLS
    const getCLS = () => {
      return new Promise((resolve) => {
        let clsValue = 0;
        new PerformanceObserver((list) => {
          for (const entry of list.getEntries()) {
            if (!entry.hadRecentInput) {
              clsValue += entry.value;
            }
          }
          resolve(clsValue);
        }).observe({ type: 'layout-shift', buffered: true });
      });
    };

    // Get TTFB
    const getTTFB = () => {
      return new Promise((resolve) => {
        const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
        resolve(navigation.responseStart - navigation.requestStart);
      });
    };

    // Get FP
    const getFP = () => {
      return new Promise((resolve) => {
        new PerformanceObserver((list) => {
          const entries = list.getEntries();
          const entry = entries[0];
          resolve(entry.startTime);
        }).observe({ type: 'paint', buffered: true });
      });
    };

    // Send metrics to analytics
    const sendToAnalytics = (metrics: Record<string, any>) => {
      // Send to Google Analytics
      if (typeof gtag === 'function') {
        Object.entries(metrics).forEach(([metric, value]) => {
          gtag('event', 'web_vitals', {
            event_category: 'Web Vitals',
            event_label: metric,
            value: Math.round(value),
            non_interaction: true
          });
        });
      }

      // Send to Facebook Pixel
      if (typeof fbq === 'function') {
        fbq('trackCustom', 'WebVitals', metrics);
      }
    };

    reportWebVitals();
  }, []);

  return null;
};

export default PerformanceMetrics;