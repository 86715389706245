import { 
  Monitor, 
  BarChart, 
  Search, 
  Megaphone, 
  Brush, 
  LineChart,
  Code,
  ShoppingBag,
  Smartphone,
  Brain,
  Video,
  Bot,
  MessageSquare
} from 'lucide-react';

const services = [
  {
    icon: BarChart,
    title: "Dijital Pazarlama",
    slug: "dijital-pazarlama",
    description: "Veriye dayalı dijital pazarlama stratejileri ile markanızın online görünürlüğünü artırıyor, hedef kitlenize ulaşmanızı sağlıyoruz.",
    features: [
      "Sosyal Medya Yönetimi",
      "Google Ads Yönetimi",
      "Facebook & Instagram Ads",
      "LinkedIn Ads",
      "TikTok Ads",
      "Display Advertising",
      "Yeniden Pazarlama"
    ],
    color: "#4CAF50",
    gradient: "from-[#E8F5E9] to-[#C8E6C9]"
  },
  {
    icon: Monitor,
    title: "UI/UX Tasarım",
    slug: "ui-ux-tasarim",
    description: "Modern ve kullanıcı dostu web siteleri tasarlıyoruz. Responsive tasarım ve optimize edilmiş kullanıcı deneyimi ile markanızı dijitalde en iyi şekilde temsil ediyoruz.",
    features: [
      "Web Sitesi Tasarımı",
      "Mobil Uygulama Tasarımı",
      "Kullanıcı Deneyimi Tasarımı",
      "Prototip Oluşturma",
      "Kullanılabilirlik Testleri"
    ],
    color: "#2196F3",
    gradient: "from-[#E3F2FD] to-[#BBDEFB]"
  },
  {
    icon: Search,
    title: "SEO ve İçerik Optimizasyonu",
    slug: "seo-ve-icerik-optimizasyonu",
    description: "Arama motorlarında üst sıralarda yer almanız için teknik SEO ve içerik optimizasyonu hizmetleri sunuyoruz.",
    features: [
      "Teknik SEO Optimizasyonu",
      "İçerik Stratejisi",
      "Anahtar Kelime Araştırması",
      "Rakip Analizi",
      "Local SEO",
      "SEO Danışmanlığı",
      "İçerik Üretimi"
    ],
    color: "#FF9800",
    gradient: "from-[#FFF3E0] to-[#FFE0B2]"
  },
  {
    icon: Megaphone,
    title: "Sosyal Medya Yönetimi",
    slug: "sosyal-medya-yonetimi",
    description: "Sosyal medya platformlarında markanızı profesyonel şekilde yönetiyor, etkileşiminizi artırıyoruz.",
    features: [
      "İçerik Üretimi",
      "Topluluk Yönetimi",
      "Influencer Marketing",
      "Sosyal Medya Reklamları",
      "Kriz Yönetimi",
      "Etkileşim Yönetimi"
    ],
    color: "#E91E63",
    gradient: "from-[#FCE4EC] to-[#F8BBD0]"
  },
  {
    icon: Brush,
    title: "Marka Kimliği",
    slug: "marka-kimligi",
    description: "Markanızın kimliğini oluşturuyor, görsel dünyasını tasarlıyor ve marka stratejinizi geliştiriyoruz.",
    features: [
      "Logo Tasarımı",
      "Kurumsal Kimlik",
      "Marka Stratejisi",
      "Ambalaj Tasarımı",
      "Marka Konumlandırma",
      "Görsel Kimlik Rehberi"
    ],
    color: "#9C27B0",
    gradient: "from-[#F3E5F5] to-[#E1BEE7]"
  },
  {
    icon: LineChart,
    title: "Dönüşüm Optimizasyonu",
    slug: "donusum-optimizasyonu",
    description: "Web sitenizin dönüşüm oranlarını artırmak için veri analizi ve A/B testleri yapıyor, kullanıcı deneyimini sürekli iyileştiriyoruz.",
    features: [
      "A/B Testleri",
      "Kullanıcı Davranış Analizi",
      "Conversion Funnel Optimizasyonu",
      "Landing Page Optimizasyonu",
      "Heat Map Analizi",
      "UX İyileştirmeleri"
    ],
    color: "#795548",
    gradient: "from-[#EFEBE9] to-[#D7CCC8]"
  },
  {
    icon: Code,
    title: "Web Geliştirme",
    slug: "web-gelistirme",
    description: "Modern teknolojilerle özel web uygulamaları geliştiriyor, işletmenize özel çözümler sunuyoruz.",
    features: [
      "Frontend Geliştirme",
      "Backend Geliştirme",
      "API Entegrasyonları",
      "Veritabanı Tasarımı",
      "Performans Optimizasyonu",
      "Güvenlik Çözümleri"
    ],
    color: "#607D8B",
    gradient: "from-[#ECEFF1] to-[#CFD8DC]"
  },
  {
    icon: ShoppingBag,
    title: "E-ticaret Çözümleri",
    slug: "e-ticaret-cozumleri",
    description: "Online satış kanallarınızı geliştiriyor, e-ticaret operasyonlarınızı profesyonel şekilde yönetiyoruz.",
    features: [
      "E-ticaret Site Tasarımı",
      "Ödeme Sistemi Entegrasyonu",
      "Stok Yönetimi",
      "Pazaryeri Entegrasyonları",
      "Sipariş Takip Sistemi",
      "E-ticaret SEO"
    ],
    color: "#FF5722",
    gradient: "from-[#FBE9E7] to-[#FFCCBC]"
  },
  {
    icon: Smartphone,
    title: "Mobil Uygulama Geliştirme",
    slug: "mobil-uygulama-gelistirme",
    description: "iOS ve Android platformları için native ve cross-platform mobil uygulamalar geliştiriyoruz.",
    features: [
      "iOS Uygulama Geliştirme",
      "Android Uygulama Geliştirme",
      "React Native",
      "Flutter",
      "UI/UX Tasarım",
      "App Store Optimizasyonu"
    ],
    color: "#3F51B5",
    gradient: "from-[#E8EAF6] to-[#C5CAE9]"
  },
  {
    icon: Brain,
    title: "Yapay Zeka ve Otomasyon",
    slug: "yapay-zeka-ve-otomasyon",
    description: "Yapay zeka destekli çözümler ve iş süreçleri otomasyonu ile verimliliğinizi artırıyoruz.",
    features: [
      "Yapay Zeka Entegrasyonu",
      "Chatbot Geliştirme",
      "İş Süreçleri Otomasyonu",
      "Veri Analizi",
      "Makine Öğrenmesi",
      "Doğal Dil İşleme"
    ],
    color: "#00BCD4",
    gradient: "from-[#E0F7FA] to-[#B2EBF2]"
  },
  {
    icon: Video,
    title: "Video Prodüksiyon",
    slug: "video-produksiyon",
    description: "Etkileyici video içerikler ile markanızın hikayesini anlatıyor, hedef kitlenizle bağ kuruyoruz.",
    features: [
      "Tanıtım Videoları",
      "Sosyal Medya Videoları",
      "Ürün Videoları",
      "Eğitim Videoları",
      "Animasyon",
      "Video SEO"
    ],
    color: "#F44336",
    gradient: "from-[#FFEBEE] to-[#FFCDD2]"
  },
  {
    icon: MessageSquare,
    title: "İçerik Pazarlama",
    slug: "icerik-pazarlama",
    description: "SEO uyumlu, özgün ve değerli içerikler üreterek organik trafiğinizi artırıyoruz.",
    features: [
      "Blog Yazıları",
      "SEO İçerikleri",
      "Sosyal Medya İçerikleri",
      "E-posta Pazarlama",
      "İnfografik Tasarım",
      "İçerik Stratejisi"
    ],
    color: "#4CAF50",
    gradient: "from-[#E8F5E9] to-[#C8E6C9]"
  },
  {
    icon: Bot,
    title: "Chatbot Geliştirme",
    slug: "chatbot-gelistirme",
    description: "Yapay zeka destekli chatbotlar ile müşteri hizmetlerinizi 7/24 aktif tutuyoruz.",
    features: [
      "AI Chatbot Geliştirme",
      "WhatsApp Bot",
      "Messenger Bot",
      "Telegram Bot",
      "Canlı Destek Entegrasyonu",
      "Çok Dilli Bot Desteği"
    ],
    color: "#009688",
    gradient: "from-[#E0F2F1] to-[#B2DFDB]"
  }
];

export default services;