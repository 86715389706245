import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import QuickContactForm from '../components/QuickContactForm';
import { 
  Megaphone, 
  Users, 
  BarChart2, 
  Target, 
  MessageCircle, 
  TrendingUp,
  Zap,
  ArrowRight,
  Instagram,
  Facebook,
  Twitter,
  Linkedin,
  PenTool,
  Shield,
  LineChart,
  PieChart
} from 'lucide-react';

function SocialMediaManagement() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const features = [
    {
      icon: PenTool,
      title: "İçerik Üretimi",
      desc: "Markanıza özel, özgün ve etkileşim yaratan içerikler üretiyoruz.",
      gradient: "from-[#E3F2FD] to-[#BBDEFB]"
    },
    {
      icon: Users,
      title: "Topluluk Yönetimi",
      desc: "Sosyal medya hesaplarınızı profesyonel şekilde yönetiyor, takipçilerinizle etkileşimi artırıyoruz.",
      gradient: "from-[#FFF3E0] to-[#FFE0B2]"
    },
    {
      icon: Target,
      title: "Influencer Marketing",
      desc: "Markanıza uygun influencer'lar ile etkili işbirlikleri gerçekleştiriyoruz.",
      gradient: "from-[#E8F5E9] to-[#C8E6C9]"
    },
    {
      icon: Megaphone,
      title: "Sosyal Medya Reklamları",
      desc: "Hedefli reklamlar ile potansiyel müşterilerinize ulaşıyor, dönüşüm oranlarınızı artırıyoruz.",
      gradient: "from-[#F3E5F5] to-[#E1BEE7]"
    },
    {
      icon: Shield,
      title: "Kriz Yönetimi",
      desc: "Olası kriz durumlarında hızlı ve etkili çözümler üreterek markanızı koruyoruz.",
      gradient: "from-[#E0F7FA] to-[#B2EBF2]"
    },
    {
      icon: MessageCircle,
      title: "Etkileşim Yönetimi",
      desc: "Takipçilerinizle aktif iletişim kurarak marka sadakatini artırıyoruz.",
      gradient: "from-[#FBE9E7] to-[#FFCCBC]"
    },
    {
      icon: LineChart,
      title: "Strateji Geliştirme",
      desc: "Markanıza özel sosyal medya stratejileri geliştiriyor ve uyguluyoruz.",
      gradient: "from-[#ECEFF1] to-[#CFD8DC]"
    },
    {
      icon: PieChart,
      title: "Performans Raporlama",
      desc: "Detaylı analizler ve raporlar ile sosyal medya performansınızı ölçümlüyoruz.",
      gradient: "from-[#E8EAF6] to-[#C5CAE9]"
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden bg-white pt-32">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="flex -space-x-3">
                    <img src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-30" />
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-20" />
                    <img src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=100&h=100&fit=crop" alt="Mutlu Müşteri" className="w-12 h-12 rounded-full border-2 border-white relative z-10" />
                  </div>
                  <div className="absolute -bottom-6 left-0 right-0">
                    <div className="bg-[#FFD700] text-black text-xs font-medium px-3 py-1 rounded-full text-center w-max mx-auto">
                      Mutlu Müşteri
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-baseline gap-2">
                    <span className="text-4xl font-bold text-[#4ADE80]">1M+</span>
                    <span className="text-gray-600 font-medium">Toplam Etkileşim</span>
                  </div>
                </div>
              </div>

              <h1 className="text-[64px] font-black leading-tight">
                Profesyonel
                <div className="relative inline-block">
                  <div className="flex items-baseline gap-3">
                    <span className="text-[#FF6B00]">Sosyal Medya</span>
                  </div>
                  <div className="absolute left-0 -bottom-2 w-full">
                    <svg className="w-full" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.5C71.5 2.5 228.5 2.5 299 5.5" stroke="#FF6B00" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-[#4ADE80]">Yönetimi</span>
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600 max-w-lg">
                Sosyal medya platformlarında markanızı profesyonel şekilde yönetiyor, etkileşiminizi artırıyoruz.
              </p>
              
              <div className="mt-12 flex items-center gap-6">
                <button 
                  onClick={() => setIsContactFormOpen(true)}
                  className="px-8 py-4 bg-[#FF6B00] text-white font-bold rounded-xl hover:bg-[#4ADE80] transition-all duration-300 inline-flex items-center gap-2"
                >
                  Hemen Başlayın
                  <Zap className="h-5 w-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <div className="relative">
                <div className="absolute inset-0 bg-[#FF6B00] opacity-10 rounded-[40px]"></div>
                <img 
                  src="https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?w=800&h=800&fit=crop" 
                  alt="Sosyal Medya Yönetimi" 
                  className="relative z-10 w-full h-[600px] object-cover object-center rounded-[40px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Özellikler ve Hizmetler
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Sosyal medya yönetimi için kapsamlı çözümler
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="group relative bg-white rounded-2xl p-8 transition-all duration-300 hover:shadow-xl border border-gray-100 hover:border-[#4ADE80]">
                <div className={`w-16 h-16 mb-6 bg-gradient-to-br ${feature.gradient} rounded-2xl flex items-center justify-center`}>
                  <feature.icon className="h-8 w-8 text-[#E91E63]" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Success Stories Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="relative inline-block">
              <h2 className="text-4xl font-bold text-gray-900">
                Başarı Hikayeleri
              </h2>
              <svg className="absolute -bottom-4 left-0 w-full" height="10" viewBox="0 0 300 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 7C71.5 2 187.5 2 299 7" stroke="#4ADE80" strokeWidth="3" strokeLinecap="round"/>
              </svg>
            </div>
            <p className="mt-8 text-lg text-gray-600">
              Sosyal medya yönetimi ile elde ettiğimiz sonuçlar
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                title: "Marka Bilinirliği",
                desc: "3 ayda Instagram takipçi sayısında %200 artış ve %150 daha fazla etkileşim",
                image: "https://images.unsplash.com/photo-1563986768609-322da13575f3?w=800&h=500&fit=crop"
              },
              {
                title: "E-ticaret Başarısı",
                desc: "Sosyal medya reklamları ile 6 ayda %300 satış artışı",
                image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=800&h=500&fit=crop"
              },
              {
                title: "Influencer Kampanyası",
                desc: "Tek kampanya ile 500.000+ kişiye organik erişim",
                image: "https://images.unsplash.com/photo-1557838923-2985c318be48?w=800&h=500&fit=crop"
              }
            ].map((story, index) => (
              <div key={index} className="group relative overflow-hidden rounded-2xl">
                <div className="relative h-64">
                  <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-70 transition-all duration-300"></div>
                  <img 
                    src={story.image} 
                    alt={story.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 p-6 flex flex-col justify-end">
                    <h3 className="text-xl font-bold text-white mb-2">{story.title}</h3>
                    <p className="text-gray-200">{story.desc}</p>
                    <a href="#" className="mt-4 inline-flex items-center text-[#4ADE80] font-medium">
                      Detayları İncele
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-3xl p-12 relative overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="animate-blob mix-blend-multiply filter blur-xl opacity-30 absolute -top-12 -left-12 w-72 h-72 bg-[#FF5733] rounded-full"></div>
              <div className="animate-blob animation-delay-2000 mix-blend-multiply filter blur-xl opacity-30 absolute -bottom-12 -right-12 w-72 h-72 bg-[#2B4EFF] rounded-full"></div>
            </div>
            
            <div className="relative text-center max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-6">Sosyal Medya Yolculuğunuza Başlayın</h2>
              <p className="text-lg text-gray-600 mb-8">
                Markanızı sosyal medyada büyütmek için hemen iletişime geçin.
              </p>
              <button 
                onClick={() => setIsContactFormOpen(true)}
                className="inline-flex items-center px-8 py-4 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 group"
              >
                Hemen İletişime Geçin
                <Zap className="ml-2 h-5 w-5 transform group-hover:-rotate-12 transition-transform duration-300" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      {/* Quick Contact Form */}
      <QuickContactForm 
        isOpen={isContactFormOpen} 
        onClose={() => setIsContactFormOpen(false)} 
      />
    </div>
  );
}

export default SocialMediaManagement;