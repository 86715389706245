import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import { supabase } from '../../lib/supabase';
import { 
  BarChart, 
  LineChart, 
  PieChart, 
  Bot,
  Search,
  Globe,
  AlertCircle,
  Calendar,
  ArrowUpRight,
  ArrowDownRight,
  Users,
  Facebook,
  Plus,
  Save,
  Loader2,
  X,
  Code,
  Copy,
  BarChart2 as ChartBar,
  Linkedin
} from 'lucide-react';
import type { AnalyticsData } from '../../types/supabase';

function Analytics() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData[]>([]);
  const [dateRange, setDateRange] = useState('last7days');
  const [showPixelEventModal, setShowPixelEventModal] = useState(false);
  const [saving, setSaving] = useState(false);

  // Google Analytics states
  const [gaCode, setGaCode] = useState('');
  const [gaId, setGaId] = useState<string | null>(null);

  // Google Tag Manager states
  const [gtmHeadCode, setGtmHeadCode] = useState('');
  const [gtmBodyCode, setGtmBodyCode] = useState('');
  const [gtmHeadId, setGtmHeadId] = useState<string | null>(null);
  const [gtmBodyId, setGtmBodyId] = useState<string | null>(null);

  // Facebook Pixel states
  const [fbPixelCode, setFbPixelCode] = useState('');
  const [fbPixelId, setFbPixelId] = useState<string | null>(null);

  // LinkedIn Insight Tag states
  const [linkedinCode, setLinkedinCode] = useState('');
  const [linkedinId, setLinkedinId] = useState<string | null>(null);

  // Facebook Pixel Event states
  const [pixelEvent, setPixelEvent] = useState({
    name: '',
    code: ''
  });

  const [pixelEvents, setPixelEvents] = useState([
    {
      id: '1',
      name: 'İletişim Formu Gönderimi',
      code: `fbq('track', 'Contact');`
    },
    {
      id: '2',
      name: 'Teklif Talebi',
      code: `fbq('track', 'Lead');`
    }
  ]);

  useEffect(() => {
    fetchAnalytics();
    fetchPixelEvents();
    fetchGtmCode();
    fetchGaCode();
    fetchFbPixelCode();
    fetchLinkedinCode();
  }, [dateRange]);

  const fetchAnalytics = async () => {
    try {
      const { data, error } = await supabase
        .from('analytics_data')
        .select('*')
        .order('visit_count', { ascending: false });

      if (error) throw error;
      setAnalyticsData(data || []);
    } catch (err: any) {
      setError('Analytics verisi yüklenirken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const fetchGaCode = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('page_path', 'google_analytics')
        .maybeSingle();

      if (data) {
        setGaCode(data.meta_title || '');
        setGaId(data.id);
      }
    } catch (err: any) {
      setError('Google Analytics kodu yüklenirken bir hata oluştu');
    }
  };

  const handleSaveGaCode = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      const data = {
        page_path: 'google_analytics',
        meta_title: gaCode,
        updated_at: new Date().toISOString()
      };

      let result;
      if (gaId) {
        result = await supabase
          .from('admin_settings')
          .update(data)
          .eq('id', gaId);
      } else {
        result = await supabase
          .from('admin_settings')
          .insert([data])
          .select()
          .single();

        if (result.data) {
          setGaId(result.data.id);
        }
      }

      if (result.error) throw result.error;

      setSuccess('Google Analytics kodu başarıyla kaydedildi');
    } catch (err: any) {
      setError('Google Analytics kodu kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const fetchGtmCode = async () => {
    try {
      const [headData, bodyData] = await Promise.all([
        supabase
          .from('admin_settings')
          .select('*')
          .eq('page_path', 'google_tag_manager_head')
          .maybeSingle(),
        supabase
          .from('admin_settings')
          .select('*')
          .eq('page_path', 'google_tag_manager_body')
          .maybeSingle()
      ]);

      if (headData.data) {
        setGtmHeadCode(headData.data.meta_title || '');
        setGtmHeadId(headData.data.id);
      }

      if (bodyData.data) {
        setGtmBodyCode(bodyData.data.meta_title || '');
        setGtmBodyId(bodyData.data.id);
      }
    } catch (err: any) {
      setError('Google Tag Manager kodu yüklenirken bir hata oluştu');
    }
  };

  const handleSaveGtmCode = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      // Save head code
      const headData = {
        page_path: 'google_tag_manager_head',
        meta_title: gtmHeadCode,
        updated_at: new Date().toISOString()
      };

      let headResult;
      if (gtmHeadId) {
        headResult = await supabase
          .from('admin_settings')
          .update(headData)
          .eq('id', gtmHeadId);
      } else {
        headResult = await supabase
          .from('admin_settings')
          .insert([headData])
          .select()
          .single();

        if (headResult.data) {
          setGtmHeadId(headResult.data.id);
        }
      }

      // Save body code
      const bodyData = {
        page_path: 'google_tag_manager_body',
        meta_title: gtmBodyCode,
        updated_at: new Date().toISOString()
      };

      let bodyResult;
      if (gtmBodyId) {
        bodyResult = await supabase
          .from('admin_settings')
          .update(bodyData)
          .eq('id', gtmBodyId);
      } else {
        bodyResult = await supabase
          .from('admin_settings')
          .insert([bodyData])
          .select()
          .single();

        if (bodyResult.data) {
          setGtmBodyId(bodyResult.data.id);
        }
      }

      if (headResult?.error) throw headResult.error;
      if (bodyResult?.error) throw bodyResult.error;

      setSuccess('Google Tag Manager kodları başarıyla kaydedildi');
    } catch (err: any) {
      setError('Google Tag Manager kodları kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const fetchFbPixelCode = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('page_path', 'facebook_pixel')
        .maybeSingle();

      if (data) {
        setFbPixelCode(data.meta_title || '');
        setFbPixelId(data.id);
      }
    } catch (err: any) {
      setError('Facebook Pixel kodu yüklenirken bir hata oluştu');
    }
  };

  const fetchLinkedinCode = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('page_path', 'linkedin_insight_tag')
        .maybeSingle();

      if (data) {
        setLinkedinCode(data.meta_title || '');
        setLinkedinId(data.id);
      }
    } catch (err: any) {
      setError('LinkedIn Insight Tag kodu yüklenirken bir hata oluştu');
    }
  };

  const handleSaveLinkedinCode = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      const data = {
        page_path: 'linkedin_insight_tag',
        meta_title: linkedinCode,
        updated_at: new Date().toISOString()
      };

      let result;
      if (linkedinId) {
        result = await supabase
          .from('admin_settings')
          .update(data)
          .eq('id', linkedinId);
      } else {
        result = await supabase
          .from('admin_settings')
          .insert([data])
          .select()
          .single();

        if (result.data) {
          setLinkedinId(result.data.id);
        }
      }

      if (result.error) throw result.error;

      setSuccess('LinkedIn Insight Tag kodu başarıyla kaydedildi');
    } catch (err: any) {
      setError('LinkedIn Insight Tag kodu kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const handleSaveFbPixelCode = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      const data = {
        page_path: 'facebook_pixel',
        meta_title: fbPixelCode,
        updated_at: new Date().toISOString()
      };

      let result;
      if (fbPixelId) {
        result = await supabase
          .from('admin_settings')
          .update(data)
          .eq('id', fbPixelId);
      } else {
        result = await supabase
          .from('admin_settings')
          .insert([data])
          .select()
          .single();

        if (result.data) {
          setFbPixelId(result.data.id);
        }
      }

      if (result.error) throw result.error;

      setSuccess('Facebook Pixel kodu başarıyla kaydedildi');
    } catch (err: any) {
      setError('Facebook Pixel kodu kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const fetchPixelEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('page_path', 'facebook_pixel_events')
        .maybeSingle();

      if (data && data.meta_title) {
        setPixelEvents(JSON.parse(data.meta_title));
      }
    } catch (err: any) {
      setError('Facebook Pixel olayları yüklenirken bir hata oluştu');
    }
  };

  const handleSavePixelEvent = async () => {
    setSaving(true);
    setError(null);
    setSuccess(null);

    try {
      const newEvents = [...pixelEvents, { 
        id: Date.now().toString(),
        ...pixelEvent 
      }];

      const { error } = await supabase
        .from('admin_settings')
        .upsert({
          page_path: 'facebook_pixel_events',
          meta_title: JSON.stringify(newEvents),
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      setPixelEvents(newEvents);
      setShowPixelEventModal(false);
      setPixelEvent({ name: '', code: '' });
      setSuccess('Facebook Pixel olayı başarıyla kaydedildi');
    } catch (err: any) {
      setError('Facebook Pixel olayı kaydedilirken bir hata oluştu');
    } finally {
      setSaving(false);
    }
  };

  const handleDeletePixelEvent = async (id: string) => {
    if (!window.confirm('Bu Facebook Pixel olayını silmek istediğinize emin misiniz?')) return;

    try {
      const newEvents = pixelEvents.filter(event => event.id !== id);

      const { error } = await supabase
        .from('admin_settings')
        .upsert({
          page_path: 'facebook_pixel_events',
          meta_title: JSON.stringify(newEvents),
          updated_at: new Date().toISOString()
        });

      if (error) throw error;

      setPixelEvents(newEvents);
      setSuccess('Facebook Pixel olayı başarıyla silindi');
    } catch (err: any) {
      setError('Facebook Pixel olayı silinirken bir hata oluştu');
    }
  };

  return (
    <AdminLayout>
      <div className="space-y-8">
        {/* Page Header */}
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Analytics</h1>
          <p className="mt-2 text-sm text-gray-600">
            Sitenizin trafik, performans analizleri ve dönüşüm olayları
          </p>
        </div>

        {/* Success & Error Messages */}
        {error && (
          <div className="flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {success && (
          <div className="flex items-center gap-2 text-green-500 bg-green-50 p-3 rounded-lg">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{success}</p>
          </div>
        )}

        {/* Google Analytics Section */}
        <div className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
          <div className="flex items-center gap-2 mb-4">
            <ChartBar className="h-5 w-5 text-gray-400" />
            <h3 className="text-lg font-semibold text-gray-900">Google Analytics</h3>
          </div>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Analytics Kodu (<code>&lt;head&gt;</code> etiketi içine)
                )
              </label>
              <div className="relative">
                <textarea
                  value={gaCode}
                  onChange={(e) => setGaCode(e.target.value)}
                  placeholder="Google Analytics kodunu buraya yapıştırın..."
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                />
                <button
                  onClick={() => copyToClipboard(gaCode)}
                  className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600"
                  title="Kopyala"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={handleSaveGaCode}
                disabled={saving}
                className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {saving ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Kaydediliyor...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Analytics Kodunu Kaydet
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Google Tag Manager Section */}
        <div className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
          <div className="flex items-center gap-2 mb-4">
            <Code className="h-5 w-5 text-gray-400" />
            <h3 className="text-lg font-semibold text-gray-900">Google Tag Manager</h3>
          </div>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Head Kodu (<code>&lt;head&gt;</code> etiketi içine)
                )
              </label>
              <div className="relative">
                <textarea
                  value={gtmHeadCode}
                  onChange={(e) => setGtmHeadCode(e.target.value)}
                  placeholder="Google Tag Manager head kodunu buraya yapıştırın..."
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                />
                <button
                  onClick={() => copyToClipboard(gtmHeadCode)}
                  className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600"
                  title="Kopyala"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Body Kodu (<code>&lt;body&gt;</code> etiketi sonrasına)
                )
              </label>
              <div className="relative">
                <textarea
                  value={gtmBodyCode}
                  onChange={(e) => setGtmBodyCode(e.target.value)}
                  placeholder="Google Tag Manager body kodunu buraya yapıştırın..."
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                />
                <button
                  onClick={() => copyToClipboard(gtmBodyCode)}
                  className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600"
                  title="Kopyala"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={handleSaveGtmCode}
                disabled={saving}
                className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {saving ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Kaydediliyor...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    GTM Kodlarını Kaydet
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Facebook Pixel Section */}
        <div className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
          <div className="flex items-center gap-2 mb-4">
            <Facebook className="h-5 w-5 text-[#1877F2]" />
            <h3 className="text-lg font-semibold text-gray-900">Facebook Pixel</h3>
          </div>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Pixel Kodu (<code>&lt;head&gt;</code> etiketi içine)
                )
              </label>
              <div className="relative">
                <textarea
                  value={fbPixelCode}
                  onChange={(e) => setFbPixelCode(e.target.value)}
                  placeholder="Facebook Pixel kodunu buraya yapıştırın..."
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                />
                <button
                  onClick={() => copyToClipboard(fbPixelCode)}
                  className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600"
                  title="Kopyala"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={handleSaveFbPixelCode}
                disabled={saving}
                className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {saving ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Kaydediliyor...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Pixel Kodunu Kaydet
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* LinkedIn Insight Tag Section */}
        <div className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
          <div className="flex items-center gap-2 mb-4">
            <Linkedin className="h-5 w-5 text-[#0A66C2]" />
            <h3 className="text-lg font-semibold text-gray-900">LinkedIn Insight Tag</h3>
          </div>
          
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Insight Tag Kodu (<code>&lt;head&gt;</code> etiketi içine)
                )
              </label>
              <div className="relative">
                <textarea
                  value={linkedinCode}
                  onChange={(e) => setLinkedinCode(e.target.value)}
                  placeholder="LinkedIn Insight Tag kodunu buraya yapıştırın..."
                  rows={5}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                />
                <button
                  onClick={() => copyToClipboard(linkedinCode)}
                  className="absolute top-2 right-2 p-2 text-gray-400 hover:text-gray-600"
                  title="Kopyala"
                >
                  <Copy className="h-4 w-4" />
                </button>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                onClick={handleSaveLinkedinCode}
                disabled={saving}
                className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {saving ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5 mr-2" />
                    Kaydediliyor...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Insight Tag Kodunu Kaydet
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Facebook Pixel Events Section */}
        <div className="bg-white p-6 rounded-2xl shadow-sm border border-gray-100">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-3">
              <Facebook className="h-6 w-6 text-[#1877F2]" />
              <h3 className="text-lg font-semibold text-gray-900">Facebook Pixel Dönüşüm Olayları</h3>
            </div>
            <button
              onClick={() => setShowPixelEventModal(true)}
              className="inline-flex items-center px-4 py-2 bg-[#4ADE80] text-white font-medium rounded-lg hover:bg-[#FF6B00] transition-all duration-300"
            >
              <Plus className="h-5 w-5 mr-2" />
              Yeni Olay Ekle
            </button>
          </div>

          <div className="space-y-4">
            {pixelEvents.map((event) => (
              <div 
                key={event.id}
                className="flex items-start justify-between p-4 bg-gray-50 rounded-xl"
              >
                <div className="space-y-2">
                  <h4 className="font-medium text-gray-900">{event.name}</h4>
                  <pre className="text-sm text-gray-600 bg-gray-100 p-2 rounded-lg">
                    <code>{event.code}</code>
                  </pre>
                </div>
                <button
                  onClick={() => handleDeletePixelEvent(event.id)}
                  className="text-gray-400 hover:text-red-500 transition-colors"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* New Pixel Event Modal */}
        {showPixelEventModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-2xl max-w-2xl w-full">
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <div className="flex items-center gap-3">
                    <Facebook className="h-6 w-6 text-[#1877F2]" />
                    <h3 className="text-2xl font-bold text-gray-900">
                      Yeni Facebook Pixel Olayı
                    </h3>
                  </div>
                  <button 
                    onClick={() => setShowPixelEventModal(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Olay Adı
                    </label>
                    <input
                      type="text"
                      value={pixelEvent.name}
                      onChange={e => setPixelEvent(prev => ({ ...prev, name: e.target.value }))}
                      placeholder="Örn: İletişim Formu Gönderimi"
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Olay Kodu
                    </label>
                    <textarea
                      value={pixelEvent.code}
                      onChange={e => setPixelEvent(prev => ({ ...prev, code: e.target.value }))}
                      placeholder="Örn: fbq('track', 'Contact');"
                      rows={4}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#4ADE80] focus:border-transparent font-mono text-sm"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Facebook Pixel olay kodunu buraya yapıştırın.
                    </p>
                  </div>

                  <div className="flex justify-end">
                    <button
                      onClick={handleSavePixelEvent}
                      disabled={saving}
                      className="inline-flex items-center px-6 py-3 bg-[#4ADE80] text-white font-medium rounded-xl hover:bg-[#FF6B00] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {saving ? (
                        <>
                          <Loader2 className="animate-spin h-5 w-5 mr-2" />
                          Kaydediliyor...
                        </>
                      ) : (
                        <>
                          <Save className="h-5 w-5 mr-2" />
                          Olayı Kaydet
                        </>
                      )} </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}

export default Analytics;