import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    fbq: (...args: any[]) => void;
    dataLayer: any[];
  }
}

function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    // Track page views
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-8PCB81DBJ1', {
        page_path: location.pathname + location.search
      });
    }

    // Facebook Pixel page view
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
    }
  }, [location]);

  // Track conversion events
  const trackConversion = (event: string, data?: Record<string, any>) => {
    // Google Analytics
    if (typeof window.gtag === 'function') {
      window.gtag('event', event, {
        ...data,
        send_to: 'G-8PCB81DBJ1'
      });
    }

    // Facebook Pixel
    if (typeof window.fbq === 'function') {
      window.fbq('track', event, data);
    }
  };

  // Expose tracking function to window
  (window as any).trackConversion = trackConversion;

  return <>{children}</>;
}

export default AnalyticsProvider;