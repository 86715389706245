import { supabase } from '../lib/supabase';
import type { 
  BlogPost, 
  BlogCategory, 
  BlogTag,
  CreateBlogPostInput,
  UpdateBlogPostInput,
  CreateBlogCategoryInput,
  UpdateBlogCategoryInput,
  CreateBlogTagInput,
  UpdateBlogTagInput
} from '../types/blog';

export const blogService = {
  // Blog Posts
  async getPosts() {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        categories:blog_posts_categories(
          category:blog_categories(*)
        ),
        tags:blog_posts_tags(
          tag:blog_tags(*)
        )
      `)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data as BlogPost[];
  },

  async getPublishedPosts() {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        categories:blog_posts_categories(
          category:blog_categories(*)
        ),
        tags:blog_posts_tags(
          tag:blog_tags(*)
        )
      `)
      .eq('status', 'published')
      .order('published_at', { ascending: false });

    if (error) throw error;
    return data as BlogPost[];
  },

  async getPostBySlug(slug: string) {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        categories:blog_posts_categories(
          category:blog_categories(*)
        ),
        tags:blog_posts_tags(
          tag:blog_tags(*)
        )
      `)
      .eq('slug', slug)
      .single();

    if (error) throw error;
    return data as BlogPost;
  },

  async createPost(input: CreateBlogPostInput) {
    const { category_ids, tag_ids, ...postData } = input;
    
    // Create post
    const { data: post, error: postError } = await supabase
      .from('blog_posts')
      .insert(postData)
      .select()
      .single();

    if (postError) throw postError;

    // Add categories
    if (category_ids?.length) {
      const categoryRelations = category_ids.map(category_id => ({
        post_id: post.id,
        category_id
      }));

      const { error: categoriesError } = await supabase
        .from('blog_posts_categories')
        .insert(categoryRelations);

      if (categoriesError) throw categoriesError;
    }

    // Add tags
    if (tag_ids?.length) {
      const tagRelations = tag_ids.map(tag_id => ({
        post_id: post.id,
        tag_id
      }));

      const { error: tagsError } = await supabase
        .from('blog_posts_tags')
        .insert(tagRelations);

      if (tagsError) throw tagsError;
    }

    return post as BlogPost;
  },

  async updatePost(input: UpdateBlogPostInput) {
    const { id, category_ids, tag_ids, ...updateData } = input;

    // Update post
    const { data: post, error: postError } = await supabase
      .from('blog_posts')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();

    if (postError) throw postError;

    // Update categories if provided
    if (category_ids) {
      // Remove existing categories
      const { error: deleteError } = await supabase
        .from('blog_posts_categories')
        .delete()
        .eq('post_id', id);

      if (deleteError) throw deleteError;

      // Add new categories
      if (category_ids.length) {
        const categoryRelations = category_ids.map(category_id => ({
          post_id: id,
          category_id
        }));

        const { error: categoriesError } = await supabase
          .from('blog_posts_categories')
          .insert(categoryRelations);

        if (categoriesError) throw categoriesError;
      }
    }

    // Update tags if provided
    if (tag_ids) {
      // Remove existing tags
      const { error: deleteError } = await supabase
        .from('blog_posts_tags')
        .delete()
        .eq('post_id', id);

      if (deleteError) throw deleteError;

      // Add new tags
      if (tag_ids.length) {
        const tagRelations = tag_ids.map(tag_id => ({
          post_id: id,
          tag_id
        }));

        const { error: tagsError } = await supabase
          .from('blog_posts_tags')
          .insert(tagRelations);

        if (tagsError) throw tagsError;
      }
    }

    return post as BlogPost;
  },

  async deletePost(id: string) {
    const { error } = await supabase
      .from('blog_posts')
      .delete()
      .eq('id', id);

    if (error) throw error;
  },

  // Categories
  async getCategories() {
    const { data, error } = await supabase
      .from('blog_categories')
      .select('*')
      .order('name');

    if (error) throw error;
    return data as BlogCategory[];
  },

  async createCategory(input: CreateBlogCategoryInput) {
    const { data, error } = await supabase
      .from('blog_categories')
      .insert(input)
      .select()
      .single();

    if (error) throw error;
    return data as BlogCategory;
  },

  async updateCategory(input: UpdateBlogCategoryInput) {
    const { id, ...updateData } = input;
    
    const { data, error } = await supabase
      .from('blog_categories')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data as BlogCategory;
  },

  async deleteCategory(id: string) {
    const { error } = await supabase
      .from('blog_categories')
      .delete()
      .eq('id', id);

    if (error) throw error;
  },

  // Tags
  async getTags() {
    const { data, error } = await supabase
      .from('blog_tags')
      .select('*')
      .order('name');

    if (error) throw error;
    return data as BlogTag[];
  },

  async createTag(input: CreateBlogTagInput) {
    const { data, error } = await supabase
      .from('blog_tags')
      .insert(input)
      .select()
      .single();

    if (error) throw error;
    return data as BlogTag;
  },

  async updateTag(input: UpdateBlogTagInput) {
    const { id, ...updateData } = input;
    
    const { data, error } = await supabase
      .from('blog_tags')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data as BlogTag;
  },

  async deleteTag(id: string) {
    const { error } = await supabase
      .from('blog_tags')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }
};