import { useEffect } from 'react';

const SecurityHeaders = () => {
  useEffect(() => {
    // Set security headers
    const setSecurityHeaders = () => {
      // Content Security Policy
      const cspHeader = [
        "default-src 'self'",
        "script-src 'self' 'unsafe-inline' 'unsafe-eval' https://www.googletagmanager.com https://connect.facebook.net",
        "style-src 'self' 'unsafe-inline' https://fonts.googleapis.com",
        "img-src 'self' data: https: blob:",
        "font-src 'self' https://fonts.gstatic.com https://db.onlinewebfonts.com",
        "connect-src 'self' https://ixexnixdneyxwigzpnao.supabase.co https://www.google-analytics.com",
        "frame-src 'self' https://www.google.com https://www.youtube.com",
        "media-src 'self'",
        "object-src 'none'",
        "base-uri 'self'",
        "form-action 'self'",
        "frame-ancestors 'none'",
        "upgrade-insecure-requests"
      ].join('; ');

      // Set meta tags for security headers
      const securityMetaTags = [
        {
          'http-equiv': 'Content-Security-Policy',
          content: cspHeader
        },
        {
          'http-equiv': 'X-Content-Type-Options',
          content: 'nosniff'
        },
        {
          'http-equiv': 'X-Frame-Options',
          content: 'SAMEORIGIN'
        },
        {
          'http-equiv': 'X-XSS-Protection',
          content: '1; mode=block'
        },
        {
          'http-equiv': 'Referrer-Policy',
          content: 'strict-origin-when-cross-origin'
        },
        {
          'http-equiv': 'Permissions-Policy',
          content: "camera=(), microphone=(), geolocation=(self), interest-cohort=()"
        }
      ];

      // Add security meta tags to head
      securityMetaTags.forEach(({ 'http-equiv': httpEquiv, content }) => {
        let meta = document.querySelector(`meta[http-equiv="${httpEquiv}"]`);
        if (!meta) {
          meta = document.createElement('meta');
          meta.setAttribute('http-equiv', httpEquiv);
          document.head.appendChild(meta);
        }
        meta.setAttribute('content', content);
      });
    };

    setSecurityHeaders();
  }, []);

  return null;
};

export default SecurityHeaders;