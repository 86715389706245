import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  Facebook, 
  Linkedin, 
  Instagram, 
  Twitter as TwitterIcon
} from 'lucide-react';
import LanguageSelector from './LanguageSelector';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex items-center">
            <Link to="/" className="flex flex-col items-center">
              <span className="text-3xl logo-text">
                <span className="text-[#F39200]">Ajans</span>
                <span className="text-[#2196F3]">Pixel</span>
              </span>
              <span className="text-sm text-gray-500 -mt-1">Digital Agency</span>
            </Link>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="nav-link">Ana Sayfa</Link>
            <Link to="/hizmetler" className="nav-link">Hizmetler</Link>
            <Link to="/portfolyo" className="nav-link">Portfolyo</Link>
            <Link to="/ekibimiz" className="nav-link">Ekibimiz</Link>
            <div className="flex items-center space-x-4">
              <a href="#" className="social-icon-link">
                <Instagram className="h-5 w-5" />
              </a>
              <a href="#" className="social-icon-link">
                <TwitterIcon className="h-5 w-5" />
              </a>
              <a href="#" className="social-icon-link">
                <Facebook className="h-5 w-5" />
              </a>
              <a href="#" className="social-icon-link">
                <Linkedin className="h-5 w-5" />
              </a>
            </div>
            <LanguageSelector />
            <Link to="/iletisim" className="px-6 py-2.5 bg-[#FFD700] text-black font-medium rounded-lg hover:bg-[#4ADE80] transition-all duration-300">
              İletişime Geç
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)} 
              className="text-gray-600"
              aria-label={isMenuOpen ? 'Menüyü kapat' : 'Menüyü aç'}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-20 left-0 right-0 bg-white border-t border-gray-100">
          <div className="px-4 py-6 space-y-4">
            <Link to="/" className="mobile-nav-link block">Ana Sayfa</Link>
            <Link to="/hizmetler" className="mobile-nav-link block">Hizmetler</Link>
            <Link to="/portfolyo" className="mobile-nav-link block">Portfolyo</Link>
            <Link to="/ekibimiz" className="mobile-nav-link block">Ekibimiz</Link>
            
            {/* Language Selector */}
            <div className="py-2">
              <LanguageSelector />
            </div>
            
            {/* Mobile Contact Button */}
            <Link to="/iletisim" className="mobile-contact-button block text-center">
              İletişime Geç
            </Link>

            {/* Mobile Social Icons */}
            <div className="flex justify-center space-x-6 pt-4 border-t border-gray-100">
              <a href="#" className="mobile-social-icon">
                <Instagram className="h-5 w-5" />
              </a>
              <a href="#" className="mobile-social-icon">
                <TwitterIcon className="h-5 w-5" />
              </a>
              <a href="#" className="mobile-social-icon">
                <Facebook className="h-5 w-5" />
              </a>
              <a href="#" className="mobile-social-icon">
                <Linkedin className="h-5 w-5" />
              </a>
            </div>

            {/* Mobile Copyright */}
            <div className="text-center text-sm text-gray-600 pt-4 border-t border-gray-100">
              &copy; 2024 AjansPixel. Tüm hakları saklıdır.
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;